import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import './horizontal-scroll.scss'

const TallOuterContainer = styled.div.attrs(({ dynamicHeight }) => ({
    style: { height: `${dynamicHeight}px` }
}))`
  position: relative;
  width: 100%;
`;

const HorizontalTranslateContainer = styled.div.attrs(({ translateX }) => ({
    style: { transform: `translateX(${translateX}px)` }
}))`
  position: absolute;
  height: 100%;
  will-change: transform;
`;

const HorizontalScroll = ({ children, formattedImgArray, isDark }) => {
    const [dynamicHeight, setDynamicHeight] = useState(null);
    const [translateX, setTranslateX] = useState(0);

    const containerRef = useRef(null);
    const objectRef = useRef(null);

    

    

    useEffect(() => {
        const resizeHandler = () => {
            handleDynamicHeight(objectRef, setDynamicHeight);
        };

        const handleDynamicHeight = (ref, setDynamicHeight) => {
            const objectWidth = !!ref.current && ref.current.scrollWidth;
            const dynamicHeight = calcDynamicHeight(objectWidth);
            setDynamicHeight(dynamicHeight);
        };

        handleDynamicHeight(objectRef, setDynamicHeight);
        window.addEventListener("resize", resizeHandler);
        applyScrollListener(containerRef, setTranslateX);
    }, []);

    const calcDynamicHeight = objectWidth => {
        const vw = window.innerWidth;
        const vh = window.innerHeight;
        return objectWidth - vw + vh + 150;
    };

    

    const applyScrollListener = (ref, setTranslateX) => {
        window.addEventListener("scroll", () => {
            if (!!ref.current) {
                const offsetTop = -ref.current.offsetTop;
                setTranslateX(offsetTop);
            }
        });
    };

    return (
        <TallOuterContainer dynamicHeight={dynamicHeight}>
            <div className={`StickyInnerContainer ${isDark ? 'dark' : 'light'}`} ref={containerRef}>
                <HorizontalTranslateContainer translateX={translateX} ref={objectRef}>
                    {children}
                </HorizontalTranslateContainer>
            </div>
        </TallOuterContainer>
    );
}

export default HorizontalScroll