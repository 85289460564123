import React, { useRef, useState, useEffect } from 'react';
import CollageView from '../CollageView/CollageView'
import { contentData } from '../../../data/contentData'

import './CategoryView.scss';

const duration = 700;
const timingFunction = "ease-in-out"
const windowW = window.innerWidth;
const windowH = window.innerHeight;

const scrollToRef = (ref) => {
    (windowW < 700) && ref.current.scrollIntoView({ behavior: 'auto' })
}

const ImageView = ({ src, opacity }) => {
    let refImg = useRef(null)
    const [anchorWidth, setAnchorWidth] = useState(true)

    const handleResize = () => {
        setAnchorWidth(window.innerWidth / window.innerHeight > 1.4)
    }

    window.addEventListener('resize', handleResize)

    return (
        <div className='ImageView'>
            <img
                src={src}
                alt={'img'}
                key={src}
                className={`img-lines`}
                style={{
                    opacity: opacity,
                    transition: `opacity ${duration / 1000}s ${timingFunction} `,
                    width: `${anchorWidth ? '100vw' : 'auto'}`,
                    height: `${anchorWidth ? 'auto' : '100vh'}`,
                    bottom: `${anchorWidth ? '5rem' : 'unset'}`,
                }}
                ref={el => refImg = el}
                onLoad={() => setAnchorWidth(refImg.naturalWidth / refImg.naturalHeight < windowW / windowH)}
            />
        </div>
    )

}

const VideoView = ({ src, opacity }) => {
    let refImg = useRef(null)
    const [anchorWidth, setAnchorWidth] = useState(false)

    const handleResize = () => {
        setAnchorWidth(window.innerWidth / window.innerHeight > 2)
    }

    window.addEventListener('resize', handleResize)
    return (
        <div className='ImageView'>
            <video
                style={{
                    opacity: opacity,
                    transition: `opacity ${duration / 1000}s ${timingFunction} `,
                    width: `${anchorWidth ? '100vw' : 'auto'}`,
                    height: `${anchorWidth ? 'auto' : '100vh'}`,
                    bottom: `${anchorWidth ? '5rem' : 'unset'}`,
                }}
                muted
                className={`img-lines`}
                // playsinline
                autoPlay
                autostart
                playsInline
                loop
                src={src}
                key={src}
                type="video/mp4"
                ref={el => refImg = el}
                onLoad={() => setAnchorWidth(refImg.naturalWidth / refImg.naturalHeight < windowW / windowH)}
            />
        </div>

    )
}

const CategoryView = (props) => {
    const [detailsData, setDetailsData] = useState([]);

    const refCenterX = useRef(null);
    const executeScrollToCenterHoriz = () => {
        scrollToRef(refCenterX);
    }

    useEffect(() => {
        const generatedDetailsArray = contentData.map(view => {
            return view.topics.map(topic => {
                const obj = {
                    detailId: topic.detailId,
                    imgSrc: topic.imgSrc,
                    positionId: topic.positionId,
                    themeId: topic.themeId
                }
                return obj
            })
        })
        setDetailsData(generatedDetailsArray);
    }, [])

    useEffect(() => {
        refCenterX && refCenterX.current && executeScrollToCenterHoriz();
    }, [])

    const renderComposedView = () => {
        return (
            <div className='CategoryView' style={{ width: `100vw`, height: `100vh` }}>
                <div className='centerX' ref={refCenterX} ></div>
                <div className={`gradient-top show`}></div>
                <div className='gradient-bottom'></div>
                {props.activeItem.type === 'video'
                    ? <VideoView src={props.activeItem.real} opacity={props.photoOpacity} />
                    : <ImageView src={props.activeItem.real} opacity={props.photoOpacity} />}
                <CollageView
                    onClickTopic={props.onClickTopic}
                    src={props.activeItem.lines}
                    opacity={1 - props.photoOpacity}
                    topicsArray={detailsData[props.activeIndex]}
                    viewIndex={props.activeIndex} />
            </div>

        )
    }


    return (
        <div>
            {props.activeItem &&
                renderComposedView()}
        </div>

    );
}

export default CategoryView;