import React, { useState, useEffect } from 'react';
import Sticky from 'react-sticky-el';
import ImagesZoom from '../ImagesZoom/ImagesZoom';
import ExtraBlock from '../ExtraBlock/ExtraBlock';

import './SectionView3.scss';


const SectionView3 = (props) => {
    const [formattedText, setFormattedText] = useState([])

    useEffect(() => {
        const paragraphArr = props.text && props.text.map(item => {
            return (item.content && item.content[0] && item.content[0].value)
        })
        setFormattedText(paragraphArr)
    }, [props.text])

    const renderText = () => {
        return (
            <div>
                {formattedText && formattedText.length > 0 && formattedText.map(item => {
                    return item
                })}
            </div>
        )
    }

    return (
        <Sticky style={{ width: `100vw` }}>
            <div className={`SectionView3 detail fixed-section ${!props.isOdd ? 'light' : 'dark'} detail `} >
                {props.extraText && <ExtraBlock extraText={props.extraText} isOdd={props.isOdd}  extraImg={props.extraImg}/>}
                <div className='detail-content3'>
                    <div className='images'>
                        <ImagesZoom imgArray={props.imgArray} scrollY={props.scrollY} offsetTop={props.offsetTop} id={props.id} />
                    </div>
                    <div className='text'>
                        {renderText()}
                    </div>
                </div>
            </div>
        </Sticky>
    )
}

export default SectionView3;