
import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseCross } from '../../assets/cross_white.svg'
import { useGlobal, setGlobal } from 'reactn';
import { useHistory } from "react-router-dom";

import './NavigationView.scss'
import IndexItems from './IndexItems/IndexItems';
import MediaFooter from '../../components/MediaFooter/MediaFooter';

const NavigationView = (props) => {
    const [loaded, setLoaded] = useState(0);
    const [activeViewIndex] = useGlobal('activeViewIndex');
    const [language] = useGlobal('language');
    let history = useHistory();

    useEffect(() => {
        setLoaded(1)
    }, [])

    const handleClickTopic = (topic) => {
        props.scrollUp();
        history.push(`/detail/${topic}`);
        setTimeout(() => {
            setGlobal({
                ...global,
                showNavigation: false
            })
        }, 500)
        
    }

    const handleBackClick = () => {
        setLoaded(0)
        setTimeout(() => {
            props.onClose()
        }, 500)

    }


    return (
        <div className={`NavigationView ${loaded ? 'loaded' : 'closed'}`}>
            <div className='navigation-header'>
                <div className='close-arrow' onClick={() => handleBackClick()}>
                    <CloseCross />
                </div>
            </div>
            <IndexItems activeView={activeViewIndex} onClickTopic={handleClickTopic} language={language}/>
            <MediaFooter />
        </div>
    )
}

export default React.memo(NavigationView)