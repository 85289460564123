import React, { useState, useEffect } from 'react';
import ImagesHorizontalCarousel from '../ImagesHorizontalCarousel/ImagesHorizontalCarousel'
import ImagesZoom from '../ImagesZoom/ImagesZoom'
import Sticky from 'react-sticky-el';

import './SectionView5.scss';
import Section5Alt from '../Section5_/Section5_';

const SectionView5 = (props) => {
    const [isVideo, setIsVideo] = useState(false);
    const windowW = window.innerWidth;
    const [formattedImgArray, setFormattedImgArray] = useState([]);
    const [imgSrcArray, setImgSrcArray] = useState([]);
    const [imgTitleArray, setImgTitleArray] = useState([]);
    const [imgOrVideoArray, setImgOrVideoArray] = useState([]);

    useEffect(() => {
        const procArray = !!props.imgArray && props.imgArray.length > 0 && props.imgArray.map(imgObj => {
            const obj = {
                title: imgObj.fields && imgObj.fields.title,
                imgSrc: imgObj.fields && imgObj.fields.file.url
            }
            return obj
        })
        const procArray2 = !!props.imgArray && props.imgArray.length > 0 && props.imgArray.map(imgObj => {
            const obj = imgObj.fields && imgObj.fields.file.url
            return obj
        })
        const procArray3 = !!props.imgArray && props.imgArray.length > 0 && props.imgArray.map(imgObj => {
            const obj = imgObj.fields && imgObj.fields.title;
            return obj
        })
        const procArray4 = !!props.imgArray && props.imgArray.length > 0 && props.imgArray.map(imgObj => {
            const obj = imgObj.fields && imgObj.fields.file.url.includes('video');
            return obj
        })
        procArray.length > 0 && setFormattedImgArray(procArray)
        procArray2.length > 0 && setImgSrcArray(procArray2)
        procArray3.length > 0 && setImgTitleArray(procArray3)
        procArray4.length > 0 && setImgOrVideoArray(procArray4)
    }, [props])

    useEffect(() => {
        if (props.imgArray.length === 1) {
            const file = props.imgArray[0] && props.imgArray[0].fields.file.url;
            setIsVideo(file.includes('video'))
        }
    }, [props])


    const getPadding = () => {
        const isRoof = window.location.href.includes('roof')
        const isWardrobe = window.location.href.includes('wardrobe')
        const isFurniture = window.location.href.includes('furniture')
        const isGranite = window.location.href.includes('granite')

        if (isRoof) {
            return props.imgArray.length * 110
        }
        else if (isWardrobe) {
            return props.imgArray.length * 140
        } else if (isFurniture)
            return props.imgArray.length > 4 ? props.imgArray.length * 100 : props.imgArray.length * 120
        else if (isGranite) {
            return props.imgArray.length * 90
        } else {
            return props.imgArray.length * 120
        }
    }

    const renderSticky = () => {
        return (
            <Sticky>
                <div className={`SectionView5 ${props.imgArray.length === 1 ? 'centered' : 'uncentered'} ${props.isOdd ? 'light' : 'dark'} detail sticky`} style={{
                    paddingBottom: `${props.imgArray.length === 1 ? 180 :
                        getPadding()}vh`
                }}>
                    <div className='detail-content5'>
                        <div className={`images ${props.imgArray.length === 1 ? 'centered' : 'uncentered'}`}>
                            <ImagesZoom imgArray={props.imgArray} scrollY={props.scrollY} id={props.id} offsetTop={props.offsetTop} />
                        </div>
                    </div>
                </div>
            </Sticky>
        )
    }

    const renderVideoDesktop = () => {
        return (
            <div className={`SectionView5 ${props.imgArray.length === 1 ? 'centered' : 'uncentered'} ${props.isOdd ? 'light' : 'dark'} detail sticky`}>
                <div className='detail-content5'>
                    <div className={`images ${props.imgArray.length === 1 ? 'centered' : 'uncentered'}`}>
                        <ImagesZoom imgArray={props.imgArray} scrollY={props.scrollY} id={props.id} offsetTop={props.offsetTop} />
                    </div>
                </div>
            </div>
        )
    }

    const renderAltSolution = () => {
        return (
            <Section5Alt isDark={props.isOdd}
                imgArray={props.imgArray}
                arrayLength={props.imgArray.length}
                formattedImgArray={formattedImgArray}
                imgSrcArray={imgSrcArray}
                imgTitleArray={imgTitleArray}
                id={props.id}
                imgOrVideoArray={imgOrVideoArray} />
        )
    }

    return (
        windowW > 800 && !props.isLandscape //&& !isVideo
            ? props.imgArray.length === 1 ? isVideo ? renderVideoDesktop() : renderSticky() : renderAltSolution()
            : <div className={`SectionView5 ${props.isOdd ? 'light' : 'dark'} ${props.imgArray.length === 1 && 'single-img'} `}>
                 <ImagesHorizontalCarousel id={props.id} imgArray={props.imgArray} scrollY={props.scrollY} offsetTop={props.offsetTop} />
            </div>
    )
}

export default SectionView5;