import React from 'react';
import { ReactComponent as Facebook } from '../../assets/facebook.svg'
import { ReactComponent as Youtube } from '../../assets/youtube.svg'
import { ReactComponent as Instagram } from '../../assets/instagram.svg'

import { useGlobal } from 'reactn';


import './MediaFooter.scss';

const MediaFooter = () => {
    const [language] = useGlobal('language');

    
    const handleClickImpressum = () => {
        const win = window.open(`${window.location.origin}/impressum`, "_blank");
        win.focus();
    }

    return (
        <div className='MediaFooter'>
            <div className='footer-container'>
                <div className='media-footer'>

                    <div className='links2'>
                        
                        <div className='media-link'>
                            <a className="example" target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/neuenationalgalerie.berlin/'>
                                <span className="hover hover-1">
                                    <Facebook /> Facebook
                            </span>
                            </a>
                        </div>

                        <div className='media-link'>
                            <a className="example" target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCQjfdJC-llj4XqT0b4xoMtA'>
                                <span className="hover hover-1">
                                    <Youtube /> Youtube
                            </span>
                            </a>
                        </div>

                        <div className='media-link'>
                            <a className="example" target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/staatlichemuseenzuberlin/'>
                                <span className="hover hover-1">
                                    <Instagram /> Instagram
                            </span>
                            </a>
                        </div>
                    </div>

                    <div className='links3'>
                        <div className='link' onClick={() => handleClickImpressum()}> {language ==='DE'? 'Impressum und Datenschutz' : 'Imprint and Privacy'}</div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default MediaFooter;