import React, { useState, useEffect, useRef } from 'react';
import { useContentful } from 'react-contentful';
import DetailHeader from '../../components/DetailHeader/DetailHeader'
import DetailFooter from '../../components/DetailFooter/DetailFooter'
import FullScreenItem from '../../components/FullScreenItem/FullScreenItem'
import SectionView from './SectionView/SectionView'
import { ReactComponent as BackArrow } from '../../assets/arrow-back.svg'
import { useHistory, useParams } from "react-router-dom";
import { useGlobal, setGlobal } from 'reactn';
import NavigationView from '../NavigationView/NavigationView'
import MenuLangSwitch from '../../components/MenuLangSwitch/MenuLangSwitch';


import './TopicDetailView.scss';
import { getThemesAlphabetically } from '../../lib/getThemesAlphabetically';

const scrollToRef = (ref) => window.scrollTo({ left: 0, top: ref.current.offsetTop })

const TopicDetailView = (props) => {
    const [topicInfo, setTopicInfo] = useState({})
    const [sectionTitlesArray, setSectionTitlesArray] = useState([]);
    let history = useHistory();
    let { topicId } = useParams();
    const [language] = useGlobal('language');
    const [fullScreenItem] = useGlobal('fullScreenItem');
    const [showNavigation] = useGlobal('showNavigation');
    const refUp = useRef(null)
    const executeScrollUp = () => scrollToRef(refUp);
    const [fixedBackArrow, setFixedBackArrow] = useState(false)
    const [navigationTimed, setNavigationTimed] = useState(false);
    const [prevTheme, setPrevTheme] = useState('');
    const [nextTheme, setNextTheme] = useState('');

    if (props.fromMainView) topicId = props.topicId;

    const findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    useEffect(() => {
        const themes_ = getThemesAlphabetically(language);
        
        const indexOfActiveElement = findWithAttr(themes_, 'themeId', topicId);
        const nextThemeItemId = indexOfActiveElement === themes_.length-1 ? 0 : indexOfActiveElement + 1;
        const prevThemeItemId = indexOfActiveElement === 0 ? themes_.length-1 : indexOfActiveElement - 1;
        
        setPrevTheme(themes_[prevThemeItemId])
        setNextTheme(themes_[nextThemeItemId])
    }, [language, topicId])


    const { data } = useContentful({
        contentType: 'topicDetail',
        query: {
            'fields.topicId': topicId,
        }
    });

    useEffect(() => {
        setTimeout(() => {
            setFixedBackArrow(true);
            setNavigationTimed(true);
        }, 1000);
    }, [])

    const handleNavigateClick = (bool) => {
        setGlobal({
            ...global,
            showNavigation: bool
        })
    }

    useEffect(() => {
        data && !!data.items && data.items.length > 0 && setTopicInfo(data.items[0].fields)
        data && !!data.items && data.items.length > 0 && setSectionTitlesArray(data.items[0].fields.sectionIds)
    }, [data])


    const handleBack = () => {
        if (props.fromMainView) {
            setFixedBackArrow(false);
            props.handleCloseTopic();
        } else {
            setFixedBackArrow(false);
            history.push('/')
        }
    }

    const handleClickTopic = (topic) => {
        history.push(`/detail/${topic.themeId}`);
        handleScrollUp();
    }

    const renderNavigationView = () => {
        return <NavigationView onClose={() => handleNavigateClick(false)} onClickTopic={handleClickTopic} scrollUp={handleScrollUp}/>
    }

    const handleScrollUp = () => {
        executeScrollUp();
    }

    return (
        <div className='TopicDetailView'>
            <div className='up' ref={refUp}></div>
            <MenuLangSwitch handleNavigateClick={handleNavigateClick} />
            <div className='arrow-back' style={{ position: `${fixedBackArrow ? 'fixed' : 'absolute'}` }} onClick={() => handleBack()}>
                <BackArrow />
            </div>
            <DetailHeader title={language === 'DE' ? topicInfo.topicTitleGerman : topicInfo.topicTitleEnglish} backgroundImg={topicInfo.titleBackground} />
            {showNavigation && navigationTimed && renderNavigationView()}
            {sectionTitlesArray.length > 0 &&
                sectionTitlesArray.map((section, id) => {
                    return <SectionView key={id} sectionId={section} id={id} activeLanguage={language} />
                })}
            <DetailFooter
                language={language}
                handleClickTopic={handleClickTopic}
                scrollUp={handleScrollUp}
                prevTopic={prevTheme} nextTopic={nextTheme} />
            {!!fullScreenItem && <FullScreenItem item={fullScreenItem}/>}
        </div>

    );
}

export default React.memo(TopicDetailView);