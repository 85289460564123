import React, { useState, useEffect } from 'react';
import { setGlobal, useGlobal } from 'reactn';

import './ExtraBlock.scss';

const ExtraBlock = (props) => {
    const [extraOpen, setExtraOpen] = useState(false);
    const [imgTitle, setImgTitle] = useState('');
    const [imgSrc, setImgSrc] = useState('');
    const [language] = useGlobal('language');
    const [item, setItem] = useState({})

    const handleClick = (open) => {
        setExtraOpen(!extraOpen)
    }

    useEffect(() => {
        !!props.extraImg && props.extraImg.fields.file && setImgSrc(props.extraImg.fields.file.url)
    }, [props.extraImg])

    useEffect(() => {
        const getTitle = (title) => {
            const titleInCorrectLang = title.split('-');
            return language === 'DE' 
                ?  titleInCorrectLang[0]
                : !!titleInCorrectLang[1] ? titleInCorrectLang[1] : titleInCorrectLang[0]
        }
        !!props.extraImg && props.extraImg.fields.title && setImgTitle(getTitle(props.extraImg.fields.title))
        const _item = {
            imgSrc: imgSrc,
            title:  !!props.extraImg && props.extraImg.fields.title
        }
        setItem(_item);
    }, [props, language, imgSrc])

    
    

    const renderText = () => {
        return (
            props.extraText && props.extraText[0] && props.extraText[0].content && props.extraText[0].content[0] &&
            props.extraText[0].content[0].value
        )
    }

    const renderImage = () => {
        return (
           <div className='img'>
               <img src={imgSrc} alt='img'/>
               <div className='title'>{imgTitle}</div>
           </div> 
        )
    }

    const handleClickItem = () => {
        setGlobal({
            ...global,
            fullScreenItem: item
        })
    }

    return (
        <div className={`ExtraBlock ${extraOpen ? 'open' : 'closed'}`}>
            <div className='rotated-content'>
                <div className={`extra-title ${extraOpen ? 'open' : 'closed'}`} >
                    Extra
                </div>
                <div className={`close-title ${extraOpen ? 'open' : 'closed'} ${props.isOdd ? 'light' :'dark'}`} >
                    Close                </div>
                <div className={`more ${extraOpen ? 'open' : 'closed'}`} onClick={() => handleClick()}>
                    +
                </div>
                <div className={`less ${extraOpen ? 'open' : 'closed'} ${props.isOdd ? 'light' :'dark'}`} onClick={() => handleClick()}>
                    -
                </div>
            </div>
            <div className={`extra-block-text ${extraOpen ? 'open' : 'closed'}`}>
                {renderText()}
            </div>
            <div className={`extra-block-image ${extraOpen ? 'open' : 'closed'}`} onClick={() => handleClickItem()}>
                {imgSrc && renderImage()}
            </div>
        </div>
    )
}

export default ExtraBlock