import React, { useState, useEffect, useRef } from 'react';

import './DetailHeader.scss';


const DetailHeader = ({ title, backgroundImg }) => {
    const [titleArray, setTitleArray] = useState([]);
    const refCenterX = useRef(null);

    useEffect(() => {
        !!title && setTitleArray(title.split(' '))
    }, [title])

    const renderImage = (src) => {
        return (
            <img src={src} className='bg-img' alt='img' />
        )
    }

    const renderVideo = (src) => {
        return (
            <video autoPlay muted loop src={src} className='bg-img' playsInline alt='img' />
        )
    }

    const renderBackground = (itemSrc) => {
        return itemSrc.toString().toLowerCase().includes('video') ? renderVideo(itemSrc) : renderImage(itemSrc)
    }



    return (
        <div className='DetailHeader'>
            <div className='topic-title'>
                {titleArray.map(word => {
                    return (
                        <div key={word}>
                            <span className='text'>{word}</span>
                        </div>
                    )
                })}


            </div>
            <div className='bg-img-container'>
                <div className='centerX' ref={refCenterX} ></div>
                {backgroundImg && backgroundImg.fields && renderBackground(backgroundImg.fields.file.url)}
            </div>
        </div>
    )
}

export default DetailHeader;