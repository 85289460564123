import React from "react";
import { setGlobal, useGlobal } from 'reactn';
import styled from "styled-components";

import HorizontalScroll from "./horizontal-scroll";

import './Section5_.scss'


const CardsContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 0 0 0 150px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10vw;
  padding-right: 9vw;

  video {
    height: 90%;
    width: auto;
  }
`;

const SampleCard = styled.div`
  position: relative;
  height: 60vh;
  width: 850px;
  // background-color: #111f30;
  margin-right: 15px;
  flex-shrink: 0;
  &:first-child {
    margin-left: 10vw;
  }
`;

const SampleCardSquared = styled.div`
  position: relative;
  height: 60vh;
  width: 550px;
  // background-color: #111f30;
  margin-right: 75px;
  flex-shrink: 0;
  // background: pink;
  &:first-child {
    margin-left: 10vw;
  }
`;

const SampleCardVertical = styled.div`
  position: relative;
  height: 60vh;
  width: 400px;
  // background-color: #111f30;
  margin-right: 75px;
  flex-shrink: 0;
  // background: pink;
  &:first-child {
    margin-left: 10vw;
  }
`;
const SampleCardLongish = styled.div`
  position: relative;
  height: 60vh;
  width: 850px;
  margin-right: 75px;
  flex-shrink: 0;
  &:first-child {
    margin-left: 10vw;
  }
`;

const SampleCardPano = styled.div`
  position: relative;
  height: 60vh;
  width: 900px;
  margin-right: 75px;
  flex-shrink: 0;
  &:first-child {
    margin-left: 10vw;
  }
`;

const SampleCardLonger = styled.div`
  position: relative;
  height: 60vh;
  width: 1020px;
  margin-right: 75px;
  flex-shrink: 0;
  &:first-child {
    margin-left: 10vw;
  }
`;

const SampleCardShorter = styled.div`
  position: relative;
  height: 60vh;
  width: 680px;
  margin-right: 75px;
  flex-shrink: 0;
  &:first-child {
    margin-left: 10vw;
  }
`;

const Section5_ = (props) => {
  const [language] = useGlobal('language');
  const getImgTitle = (title) => {
    const titleInCorrectLang = !!title && title.split('-');
    return language === 'DE'
      ? titleInCorrectLang[0]
      : !!titleInCorrectLang[1] ? titleInCorrectLang[1] : titleInCorrectLang[0]
  }

  const getCustomWidth = (i) => {
    return window.location.href.includes('column') && i === 1 ? 1000 : 800
  }

  const handleClickItem = (i) => {
    const item = props.formattedImgArray[i]
    setGlobal({
        ...global,
        fullScreenItem: item
    })
}

  const renderCard = (i) => {
    if (window.location.href.includes('furniture') && props.id === 1 && (i !== 1 && i !== 2 && i !== 4)) {
      return (
        <SampleCardSquared key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardSquared>
      )
    } else if (window.location.href.includes('furniture') && props.id === 1 && (i !== 0 && i !== 3 && i !== 5)) {
      return (
        <SampleCardShorter key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardShorter>)
    }
    else if (window.location.href.includes('column') && props.id === 0 && i === 1) {
      return (
        <SampleCardLonger key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardLonger>)
    } else if (window.location.href.includes('glass') && props.id === 2 && i === 0) {
      return (
        <SampleCardLongish key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardLongish>)
    }
    else if (window.location.href.includes('glass') && props.id === 2 && i === 1) {
      return (
        <SampleCardLonger key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardLonger>)
    }
    else if (window.location.href.includes('garden') && props.id === 1 && i === 2) {
      return (
        <SampleCardVertical key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardVertical>)
    } else if (window.location.href.includes('garden') && props.id === 2 && i === 0) {
      return (
        <SampleCardShorter key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardShorter>)
    } else if (window.location.href.includes('garden') && props.id === 1 && i === 1) {
      return (
        <SampleCardSquared key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardSquared>)
    }  else if (window.location.href.includes('refurbishment') && props.id === 0 && i === 0) {
      return (
        <SampleCardPano key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardPano>)
    } else if (window.location.href.includes('refurbishment') && props.id === 0 && i === 3) {
      return (
        <SampleCardShorter key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCardShorter>)
    } else {
      return (
        <SampleCard key={`sampleCard-${i}`} customWidth={`${getCustomWidth(i)}`} onClick={() => handleClickItem(i)}>
          {props.imgOrVideoArray[i] ? <video autoPlay playsInline controls muted src={props.imgSrcArray[i]} /> : <img alt='img' src={props.imgSrcArray[i]} />}
          <div className='footer-text'>{getImgTitle(props.imgTitleArray[i])}</div>
        </SampleCard>)
    }

  }
  const AltCards = () => {
    return (
      Array(props.arrayLength)
        .fill(0)
        .map((_e, i) => {
          return renderCard(i)
        })
    )
  }


  return (
    <>
      <div className={`Section5_ `}>
        <HorizontalScroll formattedImgArray={props.formattedImgArray}>
          <CardsContainer>
            {AltCards()}
          </CardsContainer>
        </HorizontalScroll>
      </div>

    </>
  )
}
export default Section5_;