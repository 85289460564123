 export default {
  // cookies
  getCookie (cname) {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  },

  setCookie (cname, cvalue, exhours) {
    if (this.gdprConsent() === true) {
      let expires = ''
      if (exhours !== undefined) {
        const d = new Date()
        d.setTime(d.getTime() + exhours * 60 * 60 * 1000)
        expires = `;expires=${d.toUTCString()}`
      }
      document.cookie = `${cname}=${cvalue}${expires};path=/;SameSite=None;Secure`
    }
  },  

  OptOutMatomo () {
      const paq = window._paq ? window._paq : []
      paq.push(['optUserOut'])
  },

  RemoveOptOutMatomo () {
    const paq = window._paq ? window._paq : []
    paq.push(['forgetUserOptOut'])
  }
 }
