import React, { useState, useEffect } from 'react';
import SectionView1 from '../SectionView1/SectionView1'
import SectionView2 from '../SectionView2/SectionView2'
import SectionView3 from '../SectionView3/SectionView3'
import SectionView4 from '../SectionView4/SectionView4';
import SectionView5 from '../SectionView5/SectionView5'
import SectionView7 from '../SectionView7/SectionView7'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import './DetailSection.scss';
import DetailTitle from '../DetailTitle/DetailTitle';
import { useInView } from 'react-intersection-observer'


const DetailSection = (props) => {
    const windowH = window.innerHeight;
    const windowW = window.innerWidth;

    const [sectionTitle, setSectionTitle] = useState('');
    const [text1, setText1] = useState('');
    const [extraText1, setExtraText1] = useState('');
    const [extraImg1, setExtraImg1] = useState('');
    const [text2, setText2] = useState('');
    const [extraText2, setExtraText2] = useState('');
    const [extraImg2, setExtraImg2] = useState('');
    const [text3, setText3] = useState('');
    const [extraText3, setExtraText3] = useState('');
    const [extraImg3, setExtraImg3] = useState('');
    const [images1, setImages1] = useState([]);
    const [images2, setImages2] = useState([]);
    const [images3, setImages3] = useState([]);
    const [scrollY, setScrollY] = useState(0);

    const [block1Type, setBlock1Type] = useState(0)
    const [block2Type, setBlock2Type] = useState(0)
    const [block3Type, setBlock3Type] = useState(0)

    const [ref1, inView, entry1] = useInView({ threshold: 0.1 });
    const [ref2, inView2, entry2] = useInView({ threshold: 0.1 });
    const [ref3, inView3, entry3] = useInView({ threshold: 0.1 });

    const [isLandscape, setIsLandscape] = useState(false);
    const isCurtain = window.location.href.includes('curtain');
    const isGlass = window.location.href.includes('glass');

    const findLandscape = window.matchMedia("(orientation: landscape)").matches
    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            findLandscape && setIsLandscape(true);
        } 
    }, [findLandscape])

    useEffect(() => {
        !!text1 && !!images1 && setBlock1Type(getRecommendedSectionType(text1, images1))
    }, [text1, images1])

    useEffect(() => {
        !!text2 && !!images2 && setBlock2Type(getRecommendedSectionType(text2, images2))
    }, [text2, images2])

    useEffect(() => {
        !!text3 && !!images3 && setBlock3Type(getRecommendedSectionType(text3, images3))
    }, [text3, images3])

    
    useEffect(() => {
        if (props && props.info) {
            setSectionTitle(props.activeLanguage === 'EN' ? props.info.sectionTitleEnglish : props.info.sectionTitleGerman)
            setText1(props.activeLanguage === 'EN' ? props.info.mainDescriptionEnglish && props.info.mainDescriptionEnglish.content : props.info.mainDescriptionGerman && props.info.mainDescriptionGerman.content)
            setExtraText1(props.activeLanguage === 'EN' ? props.info.extraTextBlock1English && props.info.extraTextBlock1English.content : props.info.extraTextBlock1German && props.info.extraTextBlock1German.content)
            setExtraImg1(!!props.info.extraImageBlock1 && props.info.extraImageBlock1)
            setText2(props.activeLanguage === 'EN' ? props.info.secondaryDescriptionEnglish && props.info.secondaryDescriptionEnglish.content : props.info.secondaryDescriptiongerman && props.info.secondaryDescriptiongerman.content)
            setExtraText2(props.activeLanguage === 'EN' ? props.info.extraTextBlock2English && props.info.extraTextBlock2English.content : props.info.extraTextBlock2German && props.info.extraTextBlock2German.content)
            setExtraImg2(!!props.info.extraImageBlock2 && props.info.extraImageBlock2)
            setText3(props.activeLanguage === 'EN' ? props.info.additionalTextEnglish && props.info.additionalTextEnglish.content : props.info.additionalTextGerman && props.info.additionalTextGerman.content)
            setExtraText3(props.activeLanguage === 'EN' ? props.info.extraTextBlock3English && props.info.extraTextBlock3English.content : props.info.extraTextBlock3German && props.info.extraTextBlock3German.content)
            setExtraImg3(!!props.info.extraImageBlock3 && props.info.extraImageBlock3)
            setImages1(!!props.info.images ? props.info.images : [])
            setImages2(!!props.info.secondaryImages ? props.info.secondaryImages : [])
            setImages3(!!props.info.additionalImages ? props.info.additionalImages : [])
        }
    }, [props, props.info, props.activeLanguage])


    useScrollPosition(({ prevPos, currPos }) => {
        setScrollY(currPos.y)
    })

    const isOdd = () => {
        return (props.id + 1) % 2 === 0;
    }

    const renderSections45 = (text, images, offsetTop, extraText, extraImg) => {
        return (
            <>
                <SectionView4 id={props.id} isOdd={isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop} extraText={extraText} extraImg={extraImg}/>
                {images.length > 0 && <SectionView5 id={props.id} isOdd={!isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop} isLandscape={isLandscape} />}
            </>
        )
    }

    const renderSections43 = (text, images, offsetTop, isLandscape, extraText, extraImg) => {
        // big text plus horizontal images

        return (
            <>
                <SectionView4 id={props.id} isOdd={isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop} extraText={extraText} extraImg={extraImg}/>
                <SectionView7 id={props.id} isOdd={!isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop} isLandscape={isLandscape} />
            </>
        )
    }


    // console.log('extra', props.info, extraText1)
    // Section 1 --> 1 paragraph and horizontal img carousel (better with less than 3 img)
    // Section 2 --> 1 paragraph and vertical img carousel (better with more than 3 img)
    // Section 3 --> > <3 paragraphs and 1 img zoom
    // Section 4 --> multiple paragraphs 
    // Section 5 --> multiple images
    // Section 6 --> 2 paragraphs 1 image (need to do)
    // Section 7 --> multiple paragraphs multiple images (need to do) 4 + 5
    // Section 8 --> multiple paragraphs one zoom image (need to do) 4 + 3
    // Section 9 --> no text 1 image

    const getRecommendedSectionType = (text, images) => {
        
        if (text.length === 1) {
            return images.length > 0 ? images.length > 3 ? 2 : images.length === 1 ? 3 : 2 : 10
        } else if (text.length < 3) {
            return images.length === 1 ? text.length === 2 ? 6 : 3 : 7
        } else if (text.length >= 3) {
            return images.length === 1 ? 8 : 7
        } 
    }

    const renderSectionSwitchSelector = (text, images, type, offsetTop1, extraText, extraImg) => {
        const offsetTop = - (offsetTop1 - windowH * .5)
        const offsetTop2 = - (offsetTop1 - windowH * .2)

        switch (type) {
            case 1:
                return (windowW < 600 || isLandscape) ?
                    renderSections45(text, images, offsetTop, extraText, extraImg)
                    : <SectionView1 id={props.id} isOdd={!isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop} extraText={extraText} extraImg={extraImg}/>
            case 2:
                return (windowW < 800 || isLandscape) ?
                    renderSections45(text, images, offsetTop, extraText, extraImg)
                    : <SectionView2 id={props.id} isOdd={!isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop2} extraText={extraText} extraImg={extraImg}/>
            case 3:
                return (windowW < 800 || isLandscape) ?
                    renderSections45(text, images, offsetTop, extraText, extraImg)
                    : isCurtain ? renderSections43(text, images, offsetTop, isLandscape, extraText, extraImg)
                    : <SectionView3 id={props.id} isOdd={!isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop2} extraText={extraText} extraImg={extraImg}/>
            case 6:
                return windowW < 600 ?
                    renderSections43(text, images, offsetTop, isLandscape, extraText, extraImg)
                    : renderSections43(text, images, offsetTop, isLandscape, extraText, extraImg) //<SectionView1 id={props.id} isOdd={!isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop} extraText={extraText}/>
            case 7:
                return renderSections45(text, images, offsetTop, extraText, extraImg)
            case 8:
                return renderSections45(text, images, offsetTop, extraText, extraImg)
            case 9:
                return <SectionView5 id={props.id} isOdd={!isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop} extraText={extraText} isLandscape={isLandscape} />
            case 10:
                return <SectionView4 id={props.id} isOdd={!isOdd()} imgArray={images} scrollY={scrollY} text={text} offsetTop={offsetTop} extraText={extraText} extraImg={extraImg}/>
            default:
                return null
            }
    }

    const getInvert = (id) => {
        if (id === 2 && isGlass) {
            return true
        } else if (id === 1 && window.location.href.includes('marble')) {
            return true
        } else if (id === 0 && window.location.href.includes('architect')) {
            return true
        } else if (id === 1 && window.location.href.includes('lighting')) {
            return true
        } else if (id === 1 && window.location.href.includes('building')) {
            return true
        } else if (id === 1 && window.location.href.includes('depot')) {
            return true
        } else return false
    }
    return (
        <div className='DetailSection' inView={inView}>
            <div inView={inView2}>
                <div inView={inView3}>
                    <DetailTitle title={sectionTitle} id={props.id} />

                    {/* IF ONLY 1 PARAGRAPH RENDER SECTION1, OTHERWISE RENDER 4/5 */}
                    <div className={`section-block-1  ${getInvert(props.id) && 'invert' }`} ref={ref1}>
                        {
                            !!text1 ? renderSectionSwitchSelector(text1, images1, block1Type, entry1 && entry1.target.offsetTop, extraText1, extraImg1)
                            : <SectionView7 id={props.id} isOdd={!isOdd()} imgArray={images1} scrollY={scrollY} isLandscape={isLandscape}/>
                        }
                    </div>

                    {/* IF ONLY 1 PARAGRAPH RENDER SECTION2, OTHERWISE RENDER 4/5 */}
                    <div className={`section-block-2 ${getInvert(props.id) && 'invert' } ${!isOdd()} ${props.id}`} ref={ref2}>
                        {!!text2 && renderSectionSwitchSelector(text2, images2, block2Type, entry2 && entry2.target.offsetTop, extraText2, extraImg2)}
                    </div>

                    {/* IF ONLY 1 PARAGRAPH RENDER SECTION1, OTHERWISE RENDER 4/5 */}
                    <div className={`section-block-3   ${isOdd()} ${props.id}`} ref={ref3}>
                        {!!text3 && renderSectionSwitchSelector(text3, images3, block3Type, entry3 && entry3.target.offsetTop, extraText3, extraImg3)}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DetailSection;