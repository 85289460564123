import React from 'react';
import ReactMarkdown from 'react-markdown';
import { impressumText } from '../../data/impressumText'
import './ImpressumView.scss';

const ImpressumView = () => {
    return (
        <div className='ImpressumView'>
            <ReactMarkdown>{impressumText}</ReactMarkdown>
        </div>
    )
}

export default  ImpressumView;