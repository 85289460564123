import React, { useState, useEffect } from 'react';

import './ImagesZoom.scss';
import ImageWithFooter from '../ImageWithFooter/ImageWithFooter';

const ImagesZoom = (props) => {
    const windowH = window.innerHeight;
    const windowW = window.innerWidth;
    const [formattedImgArray, setFormattedImgArray] = useState([])
    const [imgScroll, setImgScroll] = useState(0);
    const isCurtain = window.location.href.includes('curtain');
    const isRoof = window.location.href.includes('roof');
    const isCondensate = window.location.href.includes('condensate');


    useEffect(() => {
        const procArray = !!props.imgArray && props.imgArray.length > 0 && props.imgArray.map(imgObj => {
            const obj = {
                title: imgObj.fields.title,
                imgSrc: imgObj.fields.file.url
            }
            return obj
        })
        procArray.length > 0 && setFormattedImgArray(procArray)
    }, [props])


    function map_range(value, low1, high1, low2, high2) {
        return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    }

    useEffect(() => {
        const transform = windowW > 670 
        ? isCurtain ? map_range(props.scrollY, props.offsetTop, props.offsetTop + windowH, .15, 0.01) 
        : windowW > 1200 ? 
        isRoof ? map_range(props.scrollY, props.offsetTop, props.offsetTop + windowH, .42, 0.1) :
        isCondensate ? map_range(props.scrollY, props.offsetTop, props.offsetTop + windowH, .42, 0.1) 
        : map_range(props.scrollY, props.offsetTop, props.offsetTop + windowH, .22, 0.1) 
        : map_range(props.scrollY, props.offsetTop, props.offsetTop + windowH, .4, 0.1) : // change .3 up to make it scale earlier, change it down to scale later
        map_range(props.scrollY, props.offsetTop, props.offsetTop + windowH, .3, 0.01) ;

        windowW > 670 ? 
            isCurtain ? setImgScroll(Math.min(Math.max(transform, .25), .47)) : 
            isRoof ?   setImgScroll(Math.min(Math.max(transform, .25), .77)) :
            isCondensate ? setImgScroll(Math.min(Math.max(transform, .25), .73)) :
            setImgScroll(Math.min(Math.max(transform, windowW > 1200 ? .4 : .45), windowW > 1100 ? .52 : .67)) 
            : setImgScroll(Math.min(Math.max(transform, .65), 0.95))
    }, [props.scrollY, isCurtain, isCondensate, isRoof, windowW, windowH, props.offsetTop])

    return (
        <>
            {formattedImgArray.map(item => {
                return (
                     <div className={`ImagesZoom ${item.imgSrc.includes('video') ? 'static' : 'zoom'}`} key={item.imgSrc} style={{ transform: `scale(${item.imgSrc.includes('video') ? 1 : imgScroll})` }}>
                        <ImageWithFooter item={item}/>
                    </div>
                )
            })}
        </>

    )
};

export default ImagesZoom