export const impressumText = `
(English version below)

**IMPRESSUM**

Die nachstehenden Informationen enthalten die gesetzlich vorgesehenen Pflichtangaben zur Anbieterkennzeichnung, sowie wichtige rechtliche Hinweise zur Internetpräsenz der Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz.

**Anbieter**

Anbieter dieser Internetpräsenz ist im Rechtssinne die Stiftung Preußischer Kulturbesitz:

Staatliche Museen zu Berlin -- Preußischer Kulturbesitz

Generaldirektion

Stauffenbergstraße 41

10785 Berlin

Telefon: +49 (0)30 / 2660

kommunikation[at]smb.spk-berlin.de

**Vertreter**

Die Stiftung Preußischer Kulturbesitz wird gesetzlich vertreten durch ihren Präsidenten, Herrn Prof. Dr. Dr. hc. mult. Hermann Parzinger. Dieser wird vertreten durch die Stellvertretende Generaldirektorin der Staatlichen Museen zu Berlin – Preußischer Kulturbesitz, Frau Prof. Dr. Christina Haak.

**Umsatzsteueridentifikationsnummer**

Die Umsatzsteueridentifikationsnummer der Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz lautet: DE 136630206

**Verantwortlich (i.S.d.P.)**

Mechtild Kronenberg (Staatliche Museen zu Berlin)

**Projektleitung**

Joachim Jäger (Neue Nationalgalerie), Constanze von Marlin (schmedding.vonmarlin.)

**Konzeption der Website**

Joachim Jäger, Constanze von Marlin, Martin Reichert (David Chipperfield Architects), Ceren Topcu (museum4punkt0 -- SMB)

**Redaktion und Contentmanagement**

Constanze von Marlin, Ceren Topcu

**Texte**

Constanze von Marlin

**Feinkonzeption, Programmierung, Design**

NEEEU Spaces GmbH

Jesús Lazcano

Marta Soto

**Die Website wird ermöglicht durch die Wüstenrot Stiftung**

Bildnachweis Einstiegsseiten:

Vorderansicht: © Staatliche Museen zu Berlin/Reinhard Friedrich

Ausstellungshalle, Film: © Lupa Film

Skulpturengarten: © Staatliche Museen zu Berlin/Reinhard Friedrich

Ausstellungshalle, Foto: © Staatliche Museen zu Berlin/Reinhard Friedrich

Sammlungsgeschoss: © Gen Aihara

Mies van der Rohe: © Landesarchiv Berlin, F Rep 290 Nr. 0119383/Ludwig Ehlers

Sanierung: © DCA/Ute Zscharnt

Bildnachweis Collagen: Jesús Lazcano

**Rechtliche Hinweise zur Haftung / Disclaimer**

Die Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz sind um Richtigkeit und Aktualität der auf dieser Internetpräsenz bereitgestellten Informationen bemüht. Trotzdem können Fehler und Unklarheiten nicht vollständig ausgeschlossen werden. Die Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz übernehmen daher keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen.

Für Schäden materieller oder immaterieller Art, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen unmittelbar oder mittelbar verursacht werden, haften die Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz nicht, sofern ihnen nicht nachweislich vorsätzliches oder grob fahrlässiges Verschulden zur Last fällt. Gleiches gilt für kostenlos zum Download bereitgehaltene Software.

Alle Angebote sind freibleibend und unverbindlich. Die Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz behalten es sich ausdrücklich vor, Teile des Internetangebotes oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.

Die Verantwortlichkeit für „fremde Inhalte", die beispielsweise durch direkte oder indirekte Verknüpfungen (z. B. so genannte „Links") zu anderen Anbietern bereitgehalten werden, setzt unter anderem positive Kenntnis des rechtswidrigen bzw. strafbaren Inhaltes voraus. „Fremde Inhalte" sind in geeigneter Weise gekennzeichnet. Die Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz haben auf „fremde Inhalte" keinerlei Einfluss und machen sich diese Inhalte auch nicht zu Eigen. Die Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz haben keine positive Kenntnis über rechtswidrige oder anstößige Inhalte auf den verknüpften Seiten fremder Anbieter. Sollten auf den verknüpften Seiten fremder Anbieter dennoch rechtswidrige oder anstößige Inhalte enthalten sein, so distanzieren sich die Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz von diesen Inhalten ausdrücklich.

Die Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz sind bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihnen selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen.

Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind.

**Rechtswirksamkeit dieses Haftungsausschlusses**

Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.

***DATENSCHUTZERKLÄRUNG***

*Es ist den Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz ein wichtiges Anliegen, verantwortungsvoll mit Ihren persönlichen Daten umzugehen. Daher wird in den folgenden Abschnitten erläutert:*

-   *warum die Staatlichen Museen zu Berlin Daten erheben und speichern,*

-   *welche Rechte Nutzer dieser Website haben,*

-   *wie die Staatlichen Museen zu Berlin die Daten erheben und welche Daten sie speichern,*

-   *welche Arten von Cookies auf der Website der Staatlichen Museen zu Berlin eingesetzt werden,*

-   *wie Sie der Datenerfassung widersprechen oder diese vermeiden können,*

-   *wer für die Website und Datenschutz verantwortlich ist.*

***1\. Warum erheben die Staatlichen Museen zu Berlin Daten und setzen Nutzungsanalyse-Software ein?***

*Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Zum Beispiel ist die vorübergehende Speicherung der IP-Adresse durch das System technisch notwendig, um eine stabile und sichere Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss z. B. die anonymisierte IP-Adresse des Nutzers für die Dauer von 60 Tagen gespeichert bleiben. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind.*

*Die Staatlichen Museen zu Berlin analysieren die Nutzung dieser Website. Dadurch erhalten sie Informationen, um ihr Webangebot zu verbessern. Dazu zählt zum Beispiel das Wissen, wie oft bestimmte Inhalte aufgerufen wurden oder mit welchem Browser die Website betrachtet wurde. Die gespeicherten anonymisierten/pseudonymisierten Daten verwenden die Staatlichen Museen zu Berlin ausschließlich zu statistischen Zwecken. Eine andere Nutzung der Daten oder eine Weitergabe an Dritte erfolgen nicht.*

***2\. Welche Rechte haben Nutzer unserer Website?***

*In Bezug auf Ihre personenbezogenen Daten haben Sie uns gegenüber folgende Rechte:\
- das Recht auf Auskunft (Art. 15 DSGVO),*

- *das Recht auf Berichtigung und Löschung (Art. 16+17 DSGVO),*

- *das Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO),*

- *das Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO),*

- *das Recht auf Datenübertragbarkeit (Art. 20).*

*Sie haben zusätzlich das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren (Art. 77 DSGVO). Für die Staatlichen Museen zu Berlin ist die Bundesbeauftragte für Datenschutz und Informationsfreiheit (BfDI) zuständig.*

***3\. Welche Daten werden erhoben?***

1.  ***Bereitstellung der Website und Erstellung von Logfiles***

*Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners. Die Rechtsgrundlage für die Datenverarbeitung liegt in der Wahrung berechtigter Interessen gem. Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse ist die Funktionsfähigkeit und Benutzerfreundlichkeit der Website. Folgende Daten werden hierbei erhoben:*

- *IP-Adressen*

- *Log-Dateien*

- *Bestandsdaten, Verkehrsdaten und Inhaltsdaten der Websites*

- *Fehlerprotokolle*

*Durch Log-Dateien können Websitebetreiber Aktivitäten auf ihrer Website nachvollziehen. Die Access-Logs der Webserver protokollieren, welche Seitenaufrufe zu welchem Zeitpunkt stattgefunden haben. Sie beinhalten folgende Daten: IP, Verzeichnisschutzbenutzer, Datum, Uhrzeit, aufgerufene Seiten, Protokolle, Statuscode, Datenmenge, Referrer, User Agent, aufgerufener Hostname.*

*Diese Website wird entwickelt und betreut durch NEEEU Spaces GmbH.*

- *Die IP-Adressen werden anonymisiert gespeichert. Hierzu werden die letzten drei Ziffern entfernt. IPv6-Adressen werden ebenfalls anonymisiert. Die anonymisierten IP-Adressen werden für 60 Tage aufbewahrt. Angaben zum verwendeten Verzeichnisschutzbenutzer werden nach einem Tag anonymisiert.*

- *Error-Logs, welche fehlerhafte Seitenaufrufe protokollieren, werden nach sieben Tagen gelöscht. Diese beinhalten neben den Fehlermeldungen die zugreifende IP-Adresse und je nach Fehler die aufgerufene Website.*

- *Zugriffe über FTP werden anonymisiert protokolliert und für 60 Tage aufbewahrt. Mit dem Dienstleister wurde ein Vertrag zur Auftragsverarbeitung geschlossen. NEEEU Spaces GmbH ist an die Weisungen der Staatlichen Museen zu Berlin gebunden und wird regelmäßig kontrolliert.*

***b) Welche Cookies setzt diese Website ein?***

*Cookies sind kleine Textdateien, die beim Besuch einer Website auf Ihrem Computer dem von Ihnen genutzten Browser zugeordnet gespeichert werden. Über Cookies können Informationen zwischen Computerprogrammen ausgetauscht oder für einen beschränkten Zeitraum gespeichert werden. Von den gespeicherten Cookies werden bestimmte Informationen an unsere Seite übermittelt. Sie können jedoch keine Programme ausführen oder Viren auf Ihren Computer übertragen.*

*Cookies können nur gespeichert werden, wenn Sie dies in den Einstellungen Ihres Browsers erlaubt haben. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Bei jedem Zugriff auf eine Website im Internet und bei jedem Abruf einer Datei werden von Browsern üblicherweise Daten übertragen.*

*Technisch erforderliche Cookies werden gemäß Art. 6 Abs. 1 lit. E DSGVO in Verbindung mit § BDSG verarbeitet. Alle anderen Cookies werden ausschließlich mit Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. A DSGVO verarbeitet.*

*Von den Daten, die übermittelt werden, wenn Sie diese Website besuchen, speichern die Staatlichen Museen zu Berlin folgende Informationen:*

- *Browsertyp/-version*

- *Betriebssystem*

- *Referrer-URL (die weiterleitende Seite)*

- *Datum und Uhrzeit der Serveranfrage*

- *Aufgerufene Seite/n und Datei/en*

- *übertragene Datenmenge*

- *Anonymisierte/pseudonymisierte IP-Adresse*

- *Herkunftsland*

- *Meldung, ob der Zugriff/Abruf erfolgreich war.*

*Die Website der Staatlichen Museen zu Berlin verwendet Cookies sehr sparsam. Das bedeutet, dass Sie diese Website grundsätzlich auch ohne Cookies betrachten können. Die auf dieser Website eingesetzten Cookies haben zwei unterschiedliche Funktionen:*

-   *Ein Teil der eingesetzten Cookies (sog. transiente Cookies) gewährleistet, dass die Website einwandfrei funktioniert und angezeigt wird. Die Gültigkeit dieser Cookies ist auf den Websitebesuch beschränkt. Sobald Sie Ihren Browser beenden, werden diese sogenannten „Session-Cookies" gelöscht.*

-   *Für die Webanalyse setzen die Staatlichen Museen zu Berlin die von Datenschützern empfohlene Nutzungsanalyse-Software Matomo (vormals Piwik) ein. Die Verarbeitung der personenbezogenen Daten der Nutzer ermöglicht uns eine Analyse des Surfverhaltens unserer Nutzer. Wir sind durch die Auswertung der gewonnenen Daten in der Lage, Informationen über die Nutzung der einzelnen Komponenten unseres Webangebots zusammenzustellen. Dies hilft uns dabei, unser Webangebot und seine Nutzerfreundlichkeit stetig zu verbessern. Durch eine automatische Anonymisierung/Pseudonymisierung der IP-Adressen können die erhobenen Daten nicht mehr bestimmten Personen zugeordnet werden. Damit ist ausgeschlossen, dass Nutzungsdaten oder Nutzungsprofile mit personenbezogenen Daten in Verbindung gebracht werden. Die Software läuft dabei ausschließlich auf den Servern unseres Webangebots. Eine Speicherung der personenbezogenen Daten der Nutzer findet nur dort statt. Eine Weitergabe der Daten an Dritte erfolgt nicht. Je nachdem, ob Sie der Datenerhebung zugestimmt oder ihr widersprochen haben, werden entweder zwei Webanalyse-Cookies oder ein Matomo-Deaktivierungs-Cookie auf Ihrem Rechner gespeichert. Es handelt sich dabei um sogenannte persistente Cookies, die automatisiert nach zwei Jahren gelöscht werden. Sie können diese Cookies außerdem in den Sicherheitseinstellungen Ihres Browsers jederzeit selbst löschen.*

*Cookie-Einstellungen zur Datenerfassung durch die Webanalyse-Software Matomo:*

*Beim ersten Besuch unserer Website haben Sie die Möglichkeit, ausdrücklich zu erklären, dass Sie mit der Erfassung und Analyse oben beschriebener statistischer Daten durch die Staatlichen Museen zu Berlin auf unserer Website einverstanden sind. Tun Sie dies nicht, werden keine Webanalyse-Cookies gesetzt, so dass für Ihre Nutzung der Website keine Daten durch die Webanalyse-Software Matomo erfasst werden.*

***Bitte beachten Sie:** Wenn Sie in Ihrem Browser das automatische Löschen der Cookies aktiviert haben, wird beim Beenden des Programms auch der Deaktivierungs-Cookie gelöscht. In diesem Fall werden Ihnen beim nächsten Aufruf unserer Website die Cookie-Einstellmöglichkeiten erneut angezeigt, wobei Sie noch einmal die Möglichkeit haben, der Datenerfassung zu Analysezwecken ausdrücklich zuzustimmen oder die Website ohne Webanalyse-Cookies zu nutzen. Auch wenn Sie einen anderen Computer oder einen anderen Webbrowser verwenden, haben Sie erneut die Möglichkeit, der Datenerfassung zu Analysezwecken ausdrücklich zuzustimmen oder die Website ohne Webanalyse-Cookies zu nutzen.*

*Sie haben während der Nutzung unserer Website jederzeit die Möglichkeit, Ihre Cookie-Einstellungen zur Datenerfassung durch die Webanalyse-Software Matomo zu überprüfen und zu ändern. Nach einem Klick auf [diesen Link](https://www.smb.museum/en/privacy) stehen Ihnen die entsprechenden Auswahlfelder wieder zur Verfügung.*

*Nähere Informationen zu den Privatsphäreeinstellungen in Matomo finden Sie unter folgendem Link: <https://matomo.org/docs/privacy/>*

c) Welche Daten werden bei elektronischen Anfragen per Mail oder Formular gespeichert?**\
Bei Anfragen per Mail oder über ein Web-Formular müssen Sie bestimmte Daten angeben, damit die Staatlichen Museen zu Berlin mit Ihnen Kontakt aufnehmen bzw. Ihnen die gewünschten Unterlagen übersenden können. Verarbeitet werden die personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a. der DSGVO.*

*Wenn Sie den Staatlichen Museen zu Berlin bei Anfragen personenbezogene Daten übermitteln, so werden diese Daten nur für die jeweilige Korrespondenz oder zu dem im Formular angegebenen Zweck genutzt. Die Speicherung der Daten erfolgt solange es der in der Einwilligung benannte Zweck erfordert. Sie haben das Recht, Ihre Einwilligung mit Wirkung für die Zukunft zu widerrufen.*

***d) Präsenzen in Online-Kanälen und Einbindung von Diensten und Inhalten Dritter***

***Online-Kanäle der Staatlichen Museen zu Berlin**\
Wir sind in sozialen Netzwerken und Plattformen mit eigenen Kanälen aktiv, um dort mit Besucher*innen, Interessent*innen und Nutzer*innen zu kommunizieren und sie über uns und unsere Leistungen zu informieren.

***Facebook***

*Die Staatlichen Museen zu Berlin und ihre Einrichtungen betreiben mehrere Facebook-Seiten. Für die Verarbeitung personenbezogener Daten auf diesen Seiten ist die Stiftung Preußischer Kulturbesitz gemeinsam mit dem Unternehmen Facebook datenschutzrechtlich verantwortlich.*

*Betreibergesellschaft von Facebook ist die Facebook, Inc., 1 Hacker Way, Menlo Park, CA 94025, USA. Für die Verarbeitung personenbezogener Daten Verantwortlicher ist, wenn eine betroffene Person außerhalb der USA oder Kanada lebt, die Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.*

*Facebook beruft sich dabei auf die sogenannten EU-Standardvertragsklauseln als Rechtsgrundlage für die Übermittlung von Daten durch Facebook Ireland in die USA.*

*Facebook verarbeitet die Daten der Nutzer unter anderem aus Werbezwecken (Analyse und Erstellung personalisierter Werbung), zur Erstellung von Nutzerprofilen und zur Marktforschung. Facebook setzt zur Speicherung und weiteren Verarbeitung dieser Informationen Cookies ein.*

*Die Datenschutzerklärung von Facebook mit weiteren Informationen zur Datenverarbeitung finden Sie unter <https://www.facebook.com/about/privacy/>.*

*Über die sogenannten „Insights" der Facebook-Seite sind statistische Daten unterschiedlicher Kategorien für den Betreiber der Seite abrufbar. Diese Statistiken werden durch Facebook erzeugt und bereitgestellt, auf die Erzeugung und Darstellung hat der Betreiber der Seite keinen Einfluss. Hierdurch werden nachfolgende Daten durch Facebook bereitgestellt:*

*Gesamtanzahl von Seitenaufrufen, „Gefällt mir"-Angaben, Seitenaktivitäten, Beitragsinteraktionen, Reichweite, Videoansichten, Beitragsreichweite, Kommentaren, geteilten Inhalten, Antworten, Anteil Frauen und Männer, Herkunft bezogen auf Land und Stadt, Sprache, Aufrufe und Klicks im Shop, Klicks auf Routenplaner, Klicks auf Telefonnummern. Ebenfalls werden auf diesem Wege Daten zu den mit unserer Facebook-Seite verknüpften Facebook-Gruppen bereitgestellt. Näheres finden Sie hierzu in der Datenschutzerklärung von Facebook. Da nur Facebook den vollständigen Zugriff auf die Benutzerdaten hat, sind Betroffenenrechte (z. B. das Recht auf Löschung) direkt bei Facebook geltend zu machen. Bei Fragen hierzu können Sie sich an unsere Datenschutzbeauftragte wenden.*

***Instagram***

*Der Instagram-Kanal der Staatlichen Museen zu Berlin wird von Instagram Inc. (1601 Willow Road, Menlo Park, CA, 94025, USA) betrieben. Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Instagram unter: [https://instagram.com/about/legal/privacy](https://instagram.com/about/legal/privacy/).*

***Contentful***

*Für die Pflege und Übermittlung der Inhalte verwenden wir das Contentmanagementsystem Contentful. Contentful ist ein Angebot der Contentful GmbH, Ritters. 12-14, 10969 Berlin. Um eine missbräuchliche Verwendung (z.B. Denial-of-Service-Angriffe) zu vermeiden protokolliert Contentful die IP-Adresse der Endnutzer. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Contentful: <https://www.contentful.com/legal/privacy-at-contentful/privacy-notice/>*

***Spotify***

*Auf unseren Seiten sind Funktionen des Musik-Dienstes Spotify eingebunden. Anbieter ist die Spotify AB, Birger Jarlsgatan 61, 113 56 Stockholm, Schweden. Die Spotify Plugins erkennen Sie an dem grünen Logo auf unserer Seite. Eine Übersicht über die Spotify-Plugins finden Sie unter: <https://developer.spotify.com> Dadurch kann beim Besuch unserer Seiten über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Spotify-Server hergestellt werden. Spotify erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Spotify Button anklicken während Sie in Ihrem Spotify-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Spotify Profil verlinken. Dadurch kann Spotify den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wenn Sie nicht wünschen, dass Spotify den Besuch unserer Seiten Ihrem Spotify-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Spotify-Benutzerkonto aus. Spotify überträgt, verarbeitet und speichert Daten über unsere Nutzer auf Servern, die in mehreren Ländern stehen. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Spotify: <https://www.spotify.com/de/legal/privacy-policy>.*

***4\. Wer ist für Inhalte und Pflege der Website verantwortlich und wer kümmert sich um den Datenschutz?***

*Für diese Website verantwortlich im Sinne des Datenschutzes ist die Stiftung Preußischer Kulturbesitz, vertreten durch Prof. Dr. Dr. hc. mult. Hermann Parzinger*

*Stiftung Preußischer Kulturbesitz*

*Von-der-Heydt-Straße 16-18\
10785 Berlin*

*Telefon: +49 (0)30 266 412889\
E-Mail: info[at]hv.spk-berlin.de\
Website: [www.preussischer-kulturbesitz.de](https://www.preussischer-kulturbesitz.de/)*

*Für Fragen zum Bereich Datenschutz gibt es in der Stiftung Preußischer Kulturbesitz eine Datenschutzbeauftragte als Kontaktperson, die Sie unter der vorgenannten Postanschrift mit dem Zusatz "Datenschutzbeauftragte" oder über Datenschutzbeauftragte[at]hv.spk-berlin.de erreichen können.*

***5\.** **Ä****nderung der Datenschutzerklärung***

*Die Staatlichen Museen zu Berlin behalten sich vor, die Datenschutzerklärung entsprechend der technischen Weiterentwicklung und veränderter gesetzlicher Rahmenbedingungen anzupassen. Den jeweiligen Stand dieser Datenschutzerklärung finden Sie am Ende der Erklärung.*

*Stand: März 2021*



----------



**IMPRINT**

The following information contains the legally prescribed declarations regarding provider identification, as well as legal advice regarding the internet presence of the Staatliche Museen zu Berlin -- Preußischer Kulturbesitz (National Museums in Berlin -- Prussian Cultural Heritage).

**Content Provider**

The legally constituted content provider of this internet presence is:

Stiftung Preußischer Kulturbesitz

Staatlichen Museen zu Berlin

General Directorate

Stauffenbergstraße 41

D -- 10785 Berlin

Tel. +49 -- (0)30 -- 266 0

kommunikation[at]smb.spk-berlin.de

**Representative**

The Stiftung Preußischer Kulturbesitz is legally represented by their President, Prof. Dr. Dr. hc. mult. Hermann Parzinger. He is represented by the Deputy Director General of the Staatliche Museen zu Berlin, Prof. Dr. Christina Haak.

**VAT number**

The VAT number of the Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz is: DE 136630206

**Responsible Party (in Accordance with German Press Law)**

Mechtild Kronenberg (Staatliche Museen zu Berlin)

**Projekt management**

Joachim Jäger (Neue Nationalgalerie), Constanze von Marlin (schmedding.vonmarlin.)

**Concept Development**

Joachim Jäger, Constanze von Marlin, Martin Reichert (David Chipperfield Architects), Ceren Topcu (museum4punkt0 -- SMB)

**Content Management**

Constanze von Marlin, Ceren Topcu

**Texts**

Constanze von Marlin

**Translation**

Kennedy-Unglaub Translations

**Concept, Design, Programming**

NEEEU Spaces GmbH

Jesús Lazcano

Marta Soto

**This project is kindly supported by Wüstenrot Stiftung**

Picture credits landing pages:

Front view: © Staatliche Museen zu Berlin/Reinhard Friedrich

Exhibition hall, film: © Lupa Film

Sculpture garden: © Staatliche Museen zu Berlin/Reinhard Friedrich

Exhibition hall, photo: © Staatliche Museen zu Berlin/Reinhard Friedrich

Lower Story: © Staatliche Museen zu Berlin/Gen Aihara

Mies van der Rohe: © Landesarchiv Berlin

Refurbishment: © DCA/Ute Zscharnt

Picture Credit Collage: Jesús Lazcano

**Disclaimer**

The Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz are actively working to ensure that the content of these internet pages is correct and up-to-date. Nevertheless, small mistakes or irregularities cannot be completely avoided. The National Museums in Berlin do not take responsibility for the currentness, correctness, completeness or quality of the information provided.

The Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz are not liable for material or immaterial damages caused by using or not using the information provided, or caused directly or indirectly by using faulty or incomplete information, in so far as they cannot be proved to be at fault for having acted willfully or with gross negligence. The same applies to software available for free downloads.

All offers are not binding and subject to alteration without notice. The Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz explicitly reserve the right to alter, supplement or delete parts or the whole of the website's content, or to temporarily or completely discontinue publication without further notice. The liability for "external content" -- offered for instance through direct or indirect links to other web providers and pages -- presupposes a positive knowledge of any illegal or criminal content. Features of "external content" are marked in suitable fashion. The Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz have no influence whatsoever on "external content" and do not endorse any of this content. The Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz possess no positive knowledge about illegal or illicit content on pages of other providers which can be reached through links on their website. However, if these linked pages do contain illegal or illicit content, the Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz explicitly disassociate themselves from this content.

The Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz make all efforts to respect in all publications the copyright of images, audio features, video sequences and texts, to use their own images, audio features, video sequences and text, or to use images, audio features, video sequences and texts from the public domain. All trademarks mentioned on this website and (where applicable) protected by third parties are without restrictions subject to the regulations of the appropriate trade mark registration law and the property rights of the individual registered owner. The mere mention of a trade mark must not lead to the conclusion that it is not protected by the rights of third parties!

The copyright for published objects developed by the Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz themselves remains only with the Staatlichen Museen zu Berlin -- Preußischer Kulturbesitz. The duplication or appropriation of such images, audio features, video sequences and texts in other electronic or printed publications is prohibited unless explicit permission has been granted.

**Legal Effect of this Disclaimer**

This disclaimer is integral part of the website on which you found the link to this page. If any part or the whole of this text does not, no longer or not completely comply with the effective legal position, the other parts of the document remain unaffected in their content and their validity. Legal Advice on Copyright the layout of the homepage, the images used and all other content are protected by copyright.

***PRIVACY** **POLICY***

*The Staatliche Museen zu Berlin (State Museums in Berlin) -- Preussischer Kulturbesitz (Prussian Cultural Heritage Foundation) -- is committed to handling your personal data in a responsible manner and to providing transparency about its use. To this end, the following information will outline:*

-*why the Staatliche Museen zu Berlin collect and store data,*

-*what rights users of the webpages of the Staatliche Museen zu Berlin have,*

-*how the Staatliche Museen zu Berlin collect data and which data it retains,*

-*which kinds of cookies are used on the webpages of the Staatliche Museen zu Berlin,*

-*how you can object to or avoid the collection of your personal data, and*

-*who is responsible for these webpages and their data privacy policies.*

1.  ***Why does the Staatliche Museen zu Berlin collect data and utilize user analysis software?***

*As a general rule, we collect and use personal data related to our users only to the extent that this is necessary for us to ensure the functionality of the webpages and to deliver our content and services. For example, it is necessary for our system to temporarily save a user's IP address in order to facilitate a stable and secure delivery of the webpages to the computer of the user. To this end, it is necessary to save the anonymised IP address of the user for the duration of 60 days. This data will be erased as soon as it is no longer necessary.*

*The Staatliche Museen zu Berlin carry out analysis of the ways users interact with these webpages. This provides us with information to improve our online presence. This includes, for example, knowing how often particular aspects on a website are clicked on, or which browsers are used to view the pages. The data that is saved for this is anonymised or pseudo-anonymised, and is used by the Staatliche Museen zu Berlin exclusively for the purposes of statistical analysis. The data are not used for any other purpose, nor are the passed on to third parties.*

*2. What rights do users of our website have?*

*In relation to personal data and our website, you have the following rights, as defined by the EU's General Data Protection Regulation:*

-*the right of access by the data subject (art. 15 GDPR),*

-*right to rectificationand erasure (art. 16&17 GDPR),*

-*the right to restriction of processing (art. 18 GDPR),*

-*the right to object to processing (art. 21 GDPR),*

-*the right to data portability (art. 20).*

*Additionally, you have the right to make a complaint to a data privacy regulating body about the way we have processed your personal data (art. 77 GDPR). For the Staatliche Museen zu Berlin, the Federal Commissioner for Data Protection and Freedom of Information (BfDI) is the relevant body.*

***3\. What data are collected?***

***a) Functionality of the webpages and creation of log files***

*With every visit to our webpages, our system automatically collects data and information from the computer system of the computer producing the traffic. The Staatliche Museen zu Berlin has a legitimate interest in processing this personal information as enshrined in art. 6 para. 1f of the GDPR. Out legitimate interest is the functionality and user-friendliness of the website.*

*The following data are collected:*

-*IP addresses*

-*Log files*

-*Inventory data, traffic data and content data of the website*

-*Error protocols*

*Log files allow website operators to monitor activities on their websites. The access logs of the web server keep a record of when each particular page was visited. This includes the following data: IP address, directory protection users, date, time, pages visited, protocols, status codes, data size, referrer, user agent, host name used.*

*This website is developed and maintained by NEEEU Spaces GmbH.*

-*IP addresses are stored anonymously. For this purpose, the last three digits are removed. IPv6 addresses are also anonymized. The anonymized IP addresses are kept for 60 days. Information about the directory protection user is anonymized after one day.*

-*Error logs, which record erroneous page requests, are deleted after seven days. In addition to the error messages, these contain the accessing IP address and, depending on the error, the website accessed.*

-*Accesses via FTP are logged anonymously and stored for 60 days.*

*A contract for order processing has been concluded with the service provider. NEEEU spaces GmbH is bound by the instructions of the Staatlichen Museen zu Berlin and is regularly monitored.*

***b) Which cookies does this website use?***

*Cookies are small text files that are stored on your computer assigned to the browser you are using when you visit a website. Cookies can be used to exchange information between computer programs or to store information for a limited period of time. From the stored cookies, certain information is transmitted to our site. However, they cannot execute programs or transfer viruses to your computer.*

*Cookies can only be stored if you have allowed this in the settings of your browser. Therefore, as a user, you also have full control over the use of cookies. By changing the settings in your Internet browser, you can disable or restrict the transfer of cookies. Cookies that have already been stored can be deleted at any time. This can also be done automatically.*

*Every time a website is accessed on the Internet and every time a file is retrieved, browsers usually transmit data. The processing of personal data is based on Art. 6 para. 1 lit. f. Of the data transmitted when you visit this website, the Staatliche Museen zu Berlin store the following information:*

-*Browser type/version*

-*Operating system*

-*Referrer URL (the forwarding page)*

-*Date and time of server request*

-*Page/s and file/s accessed*

-*Volume of data transferred*

-*Anonymized/pseudonymized IP address*

-*Country of origin*

-*Message whether access/retrieval was successful.*

*The website of the Staatliche Museen zu Berlin uses cookies very sparingly. This means that you can generally view this website without cookies. The cookies used on this website have two different functions:*

-*Part of the cookies we use (so-called transient cookies) guarantee that the website functions seamlessly. The validity of these cookies is limited to the web session. As soon as you close your browser, these so-called "session cookies" are deleted.*

- *For website analysis, the Staatliche Museen zu Berlin employs the user analysis software Matomo (previously known as Piwik), which is recommended by data privacy advocates. The processing of the personal data of users allows us to analyse the interaction of our users with the site. Through evaluating this data, we are able to piece together information about the use of individual components on our webpages. This helps us to continually improve our webpages and make them more user-friendly. Through anonymised IP addresses, the data collected can no longer be  ascribed to particular individuals. This makes it impossible to connect usage data or usage profiles with personal data. The software for this runs exclusively on the servers of our webpages. It is only there that personal data about users is saved. This data will not be forwarded on to third parties. Depending on whether you have consented to or rejected the collection of data, there will either be two website analysis cookies or a Matomo deactivation cookie saved on your computer. These are known as "persistent cookies", which are automatically deleted after two years. You can also delete these cookies yourself in your browser security settings at any time.*

***Cookie settings regulating data collection by the website analysis software Matomo***

*When you first visit our website, you are given the opportunity to expressly give your consent to the Staatliche Museen zu Berlin collecting and analysing the statistical data described above. If you do not do this, no website analysis cookies will be activated, meaning that for your use of the website, no data will be processed by the website analysis software Matomo.*

***PLEASE NOTE**: If you have activated the automatic deletion of cookies in your browser, the deactivation cookie will also be deleted when you exit. In this case, you will have to contradict to data collection again the next time you visit this website. Even if you use a different computer or a different web browser, you must again contradict to the collection of your data.*

*At any time while you are using our website, you are able to check and change your cookie settings on data collection by the website analysis software Matomo. Click on [this link](https://www.smb.museum/en/privacy) to see your options again. More detailed information on the privacy settings in Matomo software can be found at the following link: <https://matomo.org/docs/privacy/>.*

***c) Which data are saved in the event of digital inquiries by email or using a contact form?***

*For email inquiries or contact via an online form, you are required to enter specific information so that the Staatliche Museen zu Berlin can contact you or send you the documents you have requested. Your personal data will be processed with your consent and in accordance with art. 6 para. 1a of the GDPR for email inquiries. For email inquiries, the legal basis for the processing of data is our legitimate interest as outlined in art. 6 para. 1f of the GDPR. The legitimate interest here is our capacity to quickly and easily communicate with users of our website.*

***d) Presence on online channels and incorporated content***

***Online channels of the Staatliche Museen zu Berlin***

*We are active on social media and platforms with our own profiles, in order to communicate with visitors, interested individuals and users, and to inform them about our services. This activity is regarded as serving a legitimate interest as regulated by art. 6 para. 1f of the GDPR. The legitimate interest is the quick and uncomplicated communication required for our work in the field of public relations. When accessing the respective networks and platforms, the terms and conditions and data processing policies of the respective operators apply. We also integrate content from a number of online channels into our webpages to enhance user experience by providing multimedia content. The Staatliche Museen zu Berlin, together with the respective service providers, are responsible for the collection and transmission of personal data in connection with this embedded content. It is the responsibility of the respective service provider to ensure that the applicable data processing guidelines are observed when processing the data.*

***Facebook***

*The Staatliche Museen zu Berlin and its various branches operate multiple Facebook pages. The Stiftung Preussischer Kulturbesitz and Facebook are responsible for protecting the personal data processed on these pages.The operating company of Facebook is Facebook, Inc., 1 Hacker Way, Menlo Park, CA 94025, USA. If an affected person lives outside of the USA or Canada, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland is responsible for the processing of personal data. Regarding this, Facebook invokes the EU's standard contractual clauses as the legal basis for transmitting data to the USA via Facebook Ireland. Facebook processes user data for advertising purposes (analysis and creation of personalised advertising), for the creation of user profiles and for market research. Facebook uses cookies to store and process this information. The privacy policy of Facebook along with other information on data processing can be found at:<https://www.facebook.com/about/privacy/>.*

*Via the "Insights" of our Facebook page, the operator of the page has access to different categories of statistical data. These statistics are generated and provided by Facebook; the operator of the page has no influence on their generation and presentation. The following data are provided by Facebook: Total page visits, number of "likes", page activities, post interactions, reach statistics, video views, reach of individual posts, comments, shared content, answers, gender percentages, location data (country and city), language, visits and clicks in the shop, clicks on the trip planner, clicks on telephone numbers. Facebook also provides data on the Facebook groups linked to our page. You can find more details on this in the Facebook privacy policy. Since only Facebook has full access to the user data, the rights of affected persons (e.g. the right to erasure) must be followed up directly with Facebook. If you have any questions in this regard, you can contact our data protection officer.*

***Instagram***

*The Instagram account of the Staatliche Museen zu Berlin is operated by Instagram Inc. (1601 Willow Road, Menlo Park, CA, 94025, USA). Details about how user data is managed on Instagram are available in their data policy, at:<https://instagram.com/about/legal/privacy/>.*

***Spotify***

*On our pages, functions of the music service Spotify are integrated. The provider is Spotify AB, Birger Jarlsgatan 61, 113 56 Stockholm in Sweden. You can recognize the Spotify plugins by the green logo on our site. An overview of the Spotify plugins can be found at: https://developer.spotify.com This allows a direct connection between your browser and the Spotify server when visiting our pages via the plugin. Spotify thereby receives the information that you have visited our site with your IP address. If you click the Spotify button while logged into your Spotify account, you can link the content of our pages on your Spotify profile. This allows Spotify to associate the visit to our pages with your user account. If you do not want Spotify to be able to associate the visit to our pages with your Spotify user account, please log out of your Spotify user account. Spotify transmits, processes and stores data about our users on servers located in several countries. For more information, please see Spotify's privacy policy: <https://www.spotify.com/de/legal/privacy-policy>.*

***Contentful***

*We use the content management system Contentful for the maintenance and transmission of content. Contentful is an offer of Contentful GmbH, Ritters. 12-14, 10969 Berlin. In order to prevent misuse (e.g. denial-of-service attacks), Contentful logs the IP address of end users. For more information, please see Contentful's privacy policy: https://www.contentful.com/legal/privacy-at-contentful/privacy-notice/.*

***4\. Who is responsible for content and maintenance, and who looks after data protection?***

*The Stiftung Preußischer Kulturbesitz, legally represented by the president, Prof. Dr. Dr. hc. mult. Hermann Parzinger, is responsible for data protection on these webpages.*

*Stiftung Preußischer Kulturbesitz\\
Von-der-Heydt-Straße 16-18\\
10785 Berlin\\
Germany*

*Phone: +49 (0)30 266 412889\\
E-Mail:info[at]hv.spk-berlin.de\\
Website:[www.preussischer-kulturbesitz.de](https://www.preussischer-kulturbesitz.de/)*

*For questions regarding data protection, the Stiftung Preussischer Kulturbesitz has a data protection officer whom you can contact at our postal address (addressed to the "data protection officer") or atdatenschutzbeauftragte[at]hv.spk-berlin.de*

***5\. Changes to the Privacy Policy***

*The Staatliche Museen zu Berlin reserves the right to adapt this policy to meet technological developments and changes to legal frameworks. The date of the most recent version of this data protection policy can be found at the end of this document.*

*Date: March 2021*
`

