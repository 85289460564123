import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowLeft } from '../../assets/arrowLeft1.svg'
import { ReactComponent as ArrowRight } from '../../assets/arrowRight1.svg'
import { useGlobal } from 'reactn';

import './DragToExploreView.scss';

const DragToExploreView = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [language] = useGlobal('language');

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 1000);
        setTimeout(() => {
            setLoaded(false)
        }, 1800);
    }, [])

    return (
        <div className={`DragToExploreView ${props.class}`} >
            {/* <div className={`DragToExploreView ${props.class}`} onClick={() => props.onScreenTouch()}> */}
            <div className={`content ${loaded ? 'loaded' : 'notloaded'}`}>
                <ArrowLeft />
                <div className='text'>
                    {language === 'DE' ? 
                    <div>DRAG TO <br />Explore</div>
                    : <div>DRAG TO <br />Explore</div>
                    }
                    
                </div>

                <ArrowRight />
            </div>
        </div>
    )
}

export default DragToExploreView