import React from 'react';
import './LoadingView.scss'

const LoadingView = (props) => {
    return (
        <div className='LoadingView'>
            <div className='progress-bar-container'>
                <div className='progress-bar'
                    style={{ width: `${props.loaded}%` }} />
            </div>
            <div className='columns'>
                <div className='column1'>
                    <div className='block1' />

                </div>
                <div className='text-description'>
                    <div className='block-text'>

                        Die Neue Nationalgalerie, eine Ikone der westlichen Moderne, wurde 2015–2020 mit großem Aufwand von David Chipperfield Architects denkmalgerecht saniert. Erkunden Sie die Architektur von Ludwig Mies van der Rohe anhand ihrer
                        <span className='yellow'> „Elemente“</span>.

                    </div>
                    <div className='text'>
                        <div className='block-text second'>
                            The Neue Nationalgalerie, an icon of Western modernism, was renovated from 2015–2020 by David Chipperfield Architects with great effort in keeping with its status as a listed building. Explore Ludwig Mies van der Rohe's architecture through its
                    <span className='yellow'> “elements”</span>.
                    </div>
                    </div>

                    <div className={`btn-skip ${props.loaded === 100 ? 'active' : 'unactive'}`} onClick={() => props.skipToView()}>CONTINUE</div>


                </div>
                <div className='column2'>
                    <div className='block2' />
                    <div className='block3' />
                </div>
            </div>
        </div>
    )
}

export default LoadingView