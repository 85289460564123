function onload2promise(obj) {
    return new Promise((resolve, reject) => {
        obj.onload = () => resolve(obj);
        obj.onerror = reject;
    });
}

export const preloadImage = async (uri) => {
    if (uri.toLowerCase().match("jpg|jpeg|gif|png|webp") !== null) {
        var image = new Image();
        image.src = uri;
        let imgpromise = onload2promise(image); 
        image.src = uri;
        await imgpromise;
        return uri;
    }

    if (uri.toLowerCase().match("mp4|webm|ogv") !== null) {
        var video = document.createElement('video');
        video.src = uri;
        let videopromise = onload2promise(video); 
        video.src = uri;
        await videopromise;
        return uri;
    }

}