const v0_original = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv0%2FRicht5.jpg?alt=media&token=b1856fe8-61b3-4cc3-819e-39a570c9b04c'
const v0_lines = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv0%2FMies%20view%20NS.png?alt=media&token=bed81c9f-fd70-4139-a41c-b102823c7588'

// const v1_original = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv1%2FView%20Halle.mp4?alt=media&token=c1a8e5af-d20c-4f4b-a442-75c7d23f96df'
const v1_original = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv1%2Fvideo_man.mp4?alt=media&token=8db86c72-a66a-4bb2-b07d-e3115a2e5cbb'
const v1_lines = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv1%2FInside%20view%20NS.png?alt=media&token=062fcfea-4e30-43c2-a266-b2ee538c3c68'

const v2_original = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv2%2FNNG1968-1.jpg?alt=media&token=b3b5f847-b257-40c7-8159-abf78e1ad104'
const v2_lines = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv2%2FFront%20view%20NS.png?alt=media&token=c96527cd-f201-4d71-9ec8-31dc6a40724d'

const v3_original = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv3%2FSkulpturengarten_F%20Au%C3%9Fen%2010.jpg?alt=media&token=defb3f64-87c3-437f-9402-f58912d20995'
const v3_lines = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv3%2Fsculpture%20garden%20NS%20muted%20orange.png?alt=media&token=82a685f8-6edc-4924-b904-12e74ed2df4d'

const v4_original = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv4%2Fwardrobe2.jpg?alt=media&token=5d8e6bdd-1fc3-45dd-97d1-679e5449890f'
const v4_lines = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv4%2FWardrobe%20view%20NS.png?alt=media&token=bbc87995-993a-4cf7-a616-b086c70d331e'

const v5_original = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv5%2FSugimoto_GenAihara_gro%C3%9F_%20(53).jpg?alt=media&token=5357ce6c-5240-4816-b3f7-ed5dcbfb4aa8'
const v5_lines = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv5%2FFloor%20view%20NS.png?alt=media&token=3d485b2c-114c-4ab3-8937-357b3fda40b4'

const v6_original = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv6%2F1014_09_UZ_180907_N7%20copy.jpg?alt=media&token=700602fe-382f-4c66-9672-2940454c82c3'
const v6_lines = 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv6%2Fthe%20refurbishment%202.png?alt=media&token=bcb0e5ae-a91c-4640-ae9f-0ea2594527e9'

export const themesList = [
        {
            themeTitleEn: 'Art storage',
            themeTitleGerman: 'Depot',
            themeId: 'depot'
        },
        {
            themeTitleEn: 'Building',
            themeTitleGerman: 'Bau',
            themeId: 'building'
        },
        {
            themeTitleEn: 'Roof',
            themeTitleGerman: 'Dach',
            themeId: 'roof'
        },
        {
            themeTitleEn: 'Checkroom',
            themeTitleGerman: 'Garderobe',
            themeId: 'wardrobe'
        },{
            themeTitleEn: 'Garden',
            themeTitleGerman: 'Garten',
            themeId: 'sculpture-garden'
        },
        {
            themeTitleEn: 'Glass',
            themeTitleGerman: 'Glas',
            themeId: 'glass'
        },{
            themeTitleEn: 'Granite',
            themeTitleGerman: 'Granit',
            themeId: 'granite'
        },
        {
            themeTitleEn: 'Condensation',
            themeTitleGerman: 'Kondensat',
            themeId: 'condensate'
        },{
            themeTitleEn: 'Marble',
            themeTitleGerman: 'Marmor',
            themeId: 'marble'
        },
        {
            themeTitleEn: 'Mies van der Rohe',
            themeTitleGerman: 'Mies van der Rohe',
            themeId: 'architect'
        },
        {
            themeTitleEn: 'Furniture',
            themeTitleGerman: 'Möbel',
            themeId: 'furniture'
        },
        {
            themeTitleEn: 'Column',
            themeTitleGerman: 'Säule',
            themeId: 'column'
        },
        {
            themeTitleEn: 'Refurbishment',
            themeTitleGerman: 'Sanierung',
            themeId: 'refurbishment'
        },
        {
            themeTitleEn: 'Curtain',
            themeTitleGerman: 'Vorhang',
            themeId: 'curtain'
        },
        {
            themeTitleEn: 'Lighting',
            themeTitleGerman: 'Beleuchtung',
            themeId: 'lighting'
        },
    ]
export const allAssets = [
    v0_original,
    v0_lines ,
    v1_original ,
    v1_lines ,
    v2_original ,
    v2_lines ,
    v3_original ,
    v3_lines ,
    v4_original ,
    v4_lines ,
    v5_original ,
    v5_lines ,
    v6_original ,
    v6_lines ,
]

export const viewsNamesArray = [
    {
        en: 'Front View',
        de: 'Vorderansicht'
    },
    {
        en: 'Exhibition Hall',
        de: 'Ausstellungshalle'
    },
    {
        en: 'Sculpture Garden',
        de: 'Skulpturengarten'
    },
    {
        en: 'Exhibition Hall',
        de: 'Ausstellungshalle'
    },
    {
        en: 'Lower Story',
        de: 'Sammlungsgeschoss'
    },
    {
        en: 'Mies van der Rohe',
        de: 'Mies van der Rohe'
    },
    {
        en: 'Refurbishment',
        de: 'Sanierung'
    },
]
export const contentData = [
    // FRONT VIEW BUILDING
    {
        title: 'Front View',
        type: 'photo',
        real: v2_original,
        lines: v2_lines,
        topics: [
            {
                detailId: {
                    en: 'column',
                    de: 'Säule'
                },
                positionId: 'columns',
                themeId: 'column'
            },
            {
                detailId: {
                    en: 'glass',
                    de: 'Glas'
                },
                positionId: 'glass',
                themeId: 'glass'
            },
            {
                detailId: {
                    en: 'granite',
                    de: 'Granit'
                },
                positionId: 'granite',
                themeId: 'granite'
            },
            {
                detailId: {
                    en: 'roof',
                    de: 'Dach'
                },
                positionId: 'roof',
                themeId: 'roof'
            }
        ]
    },

    // VIDEO INSIDE VIEW
    {
        title: 'Top Floor Inside View',
        type: 'video',
        real: v1_original,
        lines: v1_lines,
        topics: [
            {
                detailId: {
                    en: 'condensation',
                    de: 'Kondensat'
                },
                positionId: 'condensate',
                themeId: 'condensate'
            },
            {
                detailId: {
                    en: 'curtain',
                    de: 'Vorhang'
                },
                positionId: 'curtain',
                themeId: 'curtain'
            },
            {
                detailId: {
                    en: 'marble',
                    de: 'Marmor'
                },
                positionId: 'marble',
                themeId: 'marble'
            }
        ],
    },

    // PHOTO SCULPTURE GARDEN
    {
        title: 'Patio View',
        type: 'photo',
        real: v3_original,
        lines: v3_lines,
        topics: [
            {
                detailId: {
                    en: 'granite',
                    de: 'Granit'
                },
                positionId: 'granite-2',
                themeId: 'granite'
            },
            {
                detailId: {
                    en: 'garden',
                    de: 'Garten'
                },
                positionId: 'sculpture',
                themeId: 'sculpture-garden'
            },
            // {
            //     detailId: 'nonclicable',
            //     imgSrc: 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views_assets%2Fv3%2Fbackground.png?alt=media&token=e3ccd559-72cb-428e-baf9-0efcea80a6c4',
            //     positionId: 'nonclicable'
            // }
        ]
    },

    // PHOTO WARDROBE
    {
        title: 'Bottom Floor Inside View',
        type: 'photo',
        real: v4_original,
        lines: v4_lines,
        topics: [
            {
                detailId: {
                    en: 'condensation',
                    de: 'Kondensat'
                },
                positionId: 'condensate-2',
                themeId: 'condensate'
            },
            {
                detailId: {
                    en: 'curtain',
                    de: 'Vorhang'
                },
                positionId: 'curtain-2',
                themeId: 'curtain'
            },
            {
                detailId: {
                    en: 'furniture',
                    de: 'Möbel'
                },
                positionId: 'furniture',
                themeId: 'furniture'
            },
            {
                detailId: {
                    en: 'checkroom',
                    de: 'Garderobe'
                },
                positionId: 'wardrobe',
                themeId: 'wardrobe'
            }
        ]
    },

    // PHOTO DOWNSTAIRS
    {
        title: 'Depot Inside View',
        type: 'photo',
        real: v5_original,
        lines: v5_lines,
        topics: [
            {
                detailId: {
                    en: 'Art storage',
                    de: 'depot'
                },
                positionId: 'depot-2',
                themeId: 'depot'
            },
            {
                detailId: {
                    en: 'furniture',
                    de: 'Möbel'
                },
                positionId: 'furniture-2',
                themeId: 'furniture'
            },
            {
                detailId: {
                    en: 'garden',
                    de: 'Garten'
                },
                positionId: 'sculpture-2',
                themeId: 'sculpture-garden'
            },
            {
                detailId: {
                    en: 'lighting',
                    de: 'beleuchtung'
                },
                positionId: 'lighting',
                themeId: 'lighting'
            }
        ]
    },

    // PHOTO MIES TALK
    {
        title: 'About View',
        type: 'photo',
        real: v0_original,
        lines: v0_lines,
        topics: [
            {
                detailId: {
                    en: 'building',
                    de: 'Bau'
                },
                positionId: 'temple',
                themeId: 'building'
            },
            // {
            //     detailId: '',
            //     imgSrc: 'https://firebasestorage.googleapis.com/v0/b/neuenationalgalerie-37d76.appspot.com/o/views%2Fv0%2Fuser%20inst.png?alt=media&token=2d6f8858-b68f-49cf-a9ab-e234a2631932',
            //     positionId: ''
            // },
            {
                detailId: {
                    en: 'Mies van der Rohe',
                    de: 'Mies van der Rohe'
                },
                positionId: 'architect',
                themeId: 'architect'
            },
        ]
    },

    {
        title: 'Refurbishment View',
        type: 'photo',
        real: v6_original,
        lines: v6_lines,
        topics: [
            {
                detailId: {
                    en: 'refurbishment',
                    de: 'Sanierung'
                },
                positionId: 'refurbishment',
                themeId: 'refurbishment'
            }
        ]
    },
]