import React, { useState, useEffect } from 'react';

import './SectionView7.scss';
import ImageWithFooter from '../ImageWithFooter/ImageWithFooter';

const SectionView7 = (props) => {
    const [formattedImgArray, setFormattedImgArray] = useState([])
    const isCurtain = window.location.href.includes('curtain');


    useEffect(() => {
        const procArray = !!props.imgArray && props.imgArray.length > 0 && props.imgArray.map(imgObj => {
            const obj = {
                title: imgObj.fields.title,
                imgSrc: imgObj.fields.file.url
            }
            return obj
        })
        procArray.length > 0 && setFormattedImgArray(procArray)
    }, [props])


    return (
        <div className={`SectionView7 ${props.isOdd ? 'light' : 'dark'}`}>
        {!!formattedImgArray && formattedImgArray.length > 0 &&
            isCurtain && formattedImgArray[0].imgSrc.includes('Rechnung') ?
                <div className={`ImagesZoomCurtain vertical`} key={formattedImgArray[0].imgSrc} >
                    <ImageWithFooter item={formattedImgArray[0]} />
                </div>
            :  <ImageWithFooter item={formattedImgArray[0]} />
        }
        </div>
    )
}

export default SectionView7;
