
import React from 'react';
import { themesList } from '../../../data/contentData';
import { useGlobal } from 'reactn';
import './IndexItems.scss'

const IndexItems = (props) => {
    const [language] = useGlobal('language');

    const handleTopicClick = (topic) => {
        props.onClickTopic(topic)
    }

    const sortAlphabetically = (themes) => {
        return themes.sort((a, b) =>
            language === 'DE'
                ? (a.themeTitleGerman > b.themeTitleGerman) ? 1 : -1
                : (a.themeTitleEn > b.themeTitleEn) ? 1 : -1)
    }


    return (
        <div className='IndexItems'>
            <div className='index-title'>
                INDEX
            </div>
            <div className='views-index'>
                {sortAlphabetically(themesList).map((theme, id) => {
                    return (
                        <div key={theme.themeId} className='topic' onClick={() => { handleTopicClick(theme.themeId) }}>
                            {props.language === 'DE' ? theme.themeTitleGerman : theme.themeTitleEn}
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default IndexItems