import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useGlobal, setGlobal } from 'reactn';

import DragToExploreView from '../../components/DragToExploreView/DragToExploreView'
import CategoryView from './CategoryView/CategoryView'
import { contentData, viewsNamesArray } from '../../data/contentData';
import { ReactComponent as BackArrow } from '../../assets/back_arrow_simple.svg'
import { ReactComponent as NextArrow } from '../../assets/next_arrow_simple.svg'


import './LandingView.scss';
import NavigationView from '../NavigationView/NavigationView';
import MenuLangSwitch from '../../components/MenuLangSwitch/MenuLangSwitch';

const scrollToRef = (ref) => window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' })

const LandingView = (props) => {
    const windowW = window.innerWidth;
    const [viewsArray, setViewsArray] = useState([])
    let { view, type } = useParams();
    const [language] = useGlobal('language');
    const [firstLoad] = useGlobal('firstLoad');
    const [showingCollage] = useGlobal('showingCollage');
    const [activeViewIndex] = useGlobal('activeViewIndex');
    const [showNavigation] = useGlobal('showNavigation');
    const [isShowingTheme] = useGlobal('showingTheme');
    const [photoOpacity, setPhotoOpacity] = useState(1)
    const [showInstructions, setShowInstructions] = useState(false)
    const [displayInstructions, setDisplayInstructions] = useState(true)
    const [changedTopic, setChangedTopic] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const [nextIndexView, setNextViewIndex] = useState(0);


    const refUp = useRef(null)
    const refDown = useRef(null)
    const executeScrollUp = () => scrollToRef(refUp)
    const executeScrollDown = () => scrollToRef(refDown)

    useEffect(() => {
        const generatedViewsArray = contentData.map(view => {
            const obj = {
                real: view.real,
                lines: view.lines,
                type: view.type
            }
            return obj
        })
        setViewsArray(generatedViewsArray);
    }, [])

    useEffect(() => {
        showingCollage ? setPhotoOpacity(1) : setPhotoOpacity(0);
    }, [showingCollage])

    useEffect(() => {
        setTimeout(() => {
            setShowInstructions(true);
        }, 1000);
        setTimeout(() => {
            setShowInstructions(false);
        }, 3000);
        
        setTimeout(() => {
            handleHideInstructions();
        }, 3100);
        setTimeout(() => {
            setShowButton(true);
        }, 200);
        setLoaded(true)
    }, [])

    useEffect(() => {
        type === 'lines' && setPhotoOpacity(0)
    }, [type, view])


    const handleHideInstructions = () => {
        setDisplayInstructions(false);
        setGlobal({
            ...global,
            firstLoad: false
        })
    }


    const handleSeeNewTopic = (e, isBack) => {
        e.preventDefault();
        const nextIndex = isBack ? activeViewIndex === 0 ? viewsArray.length - 1 : (activeViewIndex - 1) : (activeViewIndex + 1) % viewsArray.length;
        setNextViewIndex(nextIndex);
        setChangedTopic(1);

        setTimeout(() => {
            setGlobal({
                ...global,
                activeViewIndex: nextIndex
            })
        }, 1000);
        setTimeout(() => {
            setChangedTopic(0)
        }, 1250);
    }

    const handleToggleViewType = () => {
        photoOpacity === 0 ? setPhotoOpacity(1) : setPhotoOpacity(0);
        setGlobal({
            ...global,
            showingCollage: photoOpacity === 0 ? true : false
        })
    }

    useScrollPosition(({ prevPos, currPos }) => {
        // handleHideInstructions()
        // console.log(currPos.y-prevPos.y, currPos.y-prevPos.y < -3)
        if (currPos.y - prevPos.y < -15 && !isShowingTheme) executeScrollDown()
        if (currPos.y - prevPos.y > 15 && !isShowingTheme) {
            executeScrollUp()
        }
    })

    const handleNavigateClick = (bool) => {
        setGlobal({
            ...global,
            showNavigation: bool
        })
    }

    const renderView = () => {
        return (
            <div style={{
                opacity: 1 - changedTopic,
            }}
                className='view'
            >
                {<CategoryView
                    language={language}
                    photoOpacity={photoOpacity}
                    activeItem={viewsArray[activeViewIndex]}
                    activeIndex={activeViewIndex}
                    onClickTopic={props.onClickTopic}
                />}
            </div>
        )
    }

    const renderActionsAlternative = () => {
        return (
            <div className={`actions-group ${firstLoad ? showButton  ? 'actions-up' : 'actions-down' : 'actions-up'}`}>
                <div className={`view-navigation-actions ${photoOpacity === 1 ? 'white' : 'black'}`}>
                    <div className='arrow' onClick={(e) => handleSeeNewTopic(e, true)}>
                        <BackArrow />
                    </div>
                    <div className='view-name'>
                        {language === 'DE' ? viewsNamesArray[activeViewIndex].de : viewsNamesArray[activeViewIndex].en}
                    </div>
                    <div className='arrow' onClick={(e) => handleSeeNewTopic(e, false)}>
                        <NextArrow />
                    </div>
                </div>
            </div>
        )
    }

    const renderInstructions = () => {
        return (
            <div className='instructions'>
                <DragToExploreView class={showInstructions ? 'show' : 'hide'} onScreenTouch={() => handleHideInstructions()} />
            </div>
        )
    }

    const renderNavigationView = () => {
        return <NavigationView onClose={() => handleNavigateClick(false)} onClickTopic={props.onClickTopic} scrollUp={() => null}/>
    }

    return (
        <div className={`LandingView`} style={{ opacity: `${loaded ? 1 : 0}`, transition: 'opacity 2s ease-in-out' }}>
            {windowW < 600 && firstLoad && displayInstructions && renderInstructions()}
            <div className='button-toggle-view' onClick={() => handleToggleViewType()}>
                <div className='text'>
                    {photoOpacity === 0 ?
                        language === 'DE' ?
                            'zurück'
                            : 'Back' :
                        language === 'DE' ?
                            'Erkunden'
                            : 'Discover'}
                </div>
            </div>
            <div className={`main-title-container ${(nextIndexView === 0 && changedTopic === 0) ? 'show' : 'hide'}`} style={{opacity: `${windowW > 370 ? 1 : 1 - changedTopic}`}}>
                <div className={`title ${(nextIndexView === 0 && changedTopic === 0) ? 'show' : 'hide'} ${photoOpacity === 0 ? 'hide' : 'show'}`} >
                    Neue<br /> Nationalgalerie <br /><span className={`${(windowW > 370 && photoOpacity !== 1) ? 'gray' : 'yellow'}`}>
                        Elements</span>
                </div>
            </div>
            <MenuLangSwitch handleNavigateClick={handleNavigateClick}/>
            {renderView()}
            {renderActionsAlternative()}
            {showNavigation && renderNavigationView()}
            <div className='up' ref={refUp}></div>
            <div className='down' ref={refDown} ></div>
            {/* {showScrollPrompt && <ScrollDownAnim pointUp={!shouldGoUp} />} */}
        </div>
    );
}

export default LandingView;