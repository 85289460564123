import React from 'react';
import { ReactComponent as LastMenu } from '../../assets/last_menu.svg';
import { useGlobal, setGlobal } from 'reactn';
import './MenuLangSwitch.scss'

const MenuLangSwitch = (props) => {
    const [language] = useGlobal('language');

    const handleToggleLanguage = () => {
        const newLang = language === 'DE' ? 'EN' : 'DE';
        setGlobal({
            ...global,
            language: newLang
        })
    }

    return (
        <div className='MenuLangSwitch'>
            <div className='language-switch' onClick={() => handleToggleLanguage()}>
                <div className='selected-language'>{language === 'DE' ? 'EN' : 'DE'}</div>
            </div>
            <div className={`menu-button`} onClick={() => props.handleNavigateClick(true)}>
                <div className='menu-desktop-mobile'><LastMenu /></div>
            </div>
        </div>
    )
}

export default MenuLangSwitch