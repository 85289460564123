import React, { useState, useEffect } from 'react';
import ImagesHorizontalCarousel from '../ImagesHorizontalCarousel/ImagesHorizontalCarousel'
import Sticky from 'react-sticky-el';
import ImagesZoom from '../ImagesZoom/ImagesZoom';
import ExtraBlock from '../ExtraBlock/ExtraBlock'

import './SectionView1.scss';

const SectionView1 = (props) => {
    const [formattedText, setFormattedText] = useState([])

    useEffect(() => {
        const paragraphArr = props.text && props.text.map(item => {
            return (item.content && item.content[0] && item.content[0].value)
        })
        setFormattedText(paragraphArr)
    }, [props.text])

    const renderText = () => {
        return (
            <div className='text-section'>
                {formattedText && formattedText.length > 0 && formattedText.map(item => {
                    return item
                })}
            </div>
        )
    }

    return (
        <Sticky >
            <div className={`SectionView1 ${props.imgArray.length === 1 ? 'centered' :'uncentered'} ${props.isOdd ? 'light' : 'dark'} detail `} 
             style={{paddingBottom: `${props.imgArray.length * 200}vh`}}
            >
                {props.extraText && <ExtraBlock extraText={props.extraText} isOdd={props.isOdd}/>}
                <div className='detail-content1'>
                    <div className='text'>
                        {renderText()}
                    </div>
                    <div className='images'>
                        {props.imgArray.length === 1 ?
                            <ImagesZoom imgArray={props.imgArray} scrollY={props.scrollY} id={props.id} offsetTop={props.offsetTop}/>
                            : <ImagesHorizontalCarousel id={props.id} imgArray={props.imgArray} scrollY={props.scrollY} offsetTop={props.offsetTop}/>}

                    </div>
                </div>
            </div>
        </Sticky> 
    )
}

export default SectionView1;