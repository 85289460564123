import React, { useState, useEffect } from 'react';
import { ReactComponent as Close } from '../../assets/close-fullscreen.svg';
import { setGlobal, useGlobal } from 'reactn';
import './FullScreenItem.scss';

const FullScreenItem = ({ item }) => {
    const [language] = useGlobal('language');
    const [imgTitle, setImgTitle] = useState('');

    useEffect(() => {
        const titleInCorrectLang = item.title.split('-');
        language === 'DE' 
        ? setImgTitle(titleInCorrectLang[0]) 
        : !!titleInCorrectLang[1] ? setImgTitle(titleInCorrectLang[1]) : setImgTitle(titleInCorrectLang[0])
    }, [language, item.title])

    const handleClickClose = () => {
        setGlobal({
            ...global,
            fullScreenItem: ''
        })
    }

    return (
        <div className='FullScreenItem'>
            <div className='close' onClick={() => handleClickClose()}>
                <Close />
            </div>
            <div className='container'>
                {item.imgSrc.includes('video') ?
                    <video className='fullscreen-img' src={item.imgSrc} autoPlay playsInline controls muted></video> :
                    <img key={item.imgSrc} className='fullscreen-img' src={item.imgSrc} alt='img' />}
                <div className='title'>
                    {imgTitle}
                </div>
            </div>

        </div>
    )
}

export default FullScreenItem