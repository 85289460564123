import React from 'react';
import { setGlobal, useGlobal } from 'reactn';
import './ImageWithFooter.scss'

const ImageWithFooter = ({ item }) => {
    const [language] = useGlobal('language');

    const getImgTitle = () => {
        const titleInCorrectLang = !!item && item.title.split('-');
        return language === 'DE' 
        ? titleInCorrectLang[0]
        : !!titleInCorrectLang[1] ? titleInCorrectLang[1] : titleInCorrectLang[0]
    }

    const handleClickItem = () => {
        setGlobal({
            ...global,
            fullScreenItem: item
        })
    }
    return (
       !!item && <div className={`ImageWithFooter`} onClick={() => handleClickItem()}>
            {item.imgSrc.includes('video') ?
                <video className='img-carousel' src={item.imgSrc} autoPlay playsInline controls muted></video> :
                <img key={item.imgSrc} className='img-carousel' src={item.imgSrc} alt='img' />}
            <div className='footer-text'>{getImgTitle()}</div>
        </div>
    )
}

export default ImageWithFooter