import React, { useState, useRef } from 'react';
import LandingView from '../LandingView/LandingView';
import { useHistory } from "react-router-dom";
import { useGlobal } from 'reactn';

import './MainView.scss';
import TopicDetailView from '../TopicDetailView/TopicDetailView';

const MainView = () => {
    const [global, setGlobal] = useGlobal('showingTheme')
    const [showTopicDetail, setShowTopicDetail] = useState(false);
    const [topicId, setTopicId] = useState('');
    const [overflowActive, setOverflowActive] = useState(false);
    const [fromTopic, setFromTopic] = useState(false);
    const refDown = useRef(null);
    let history = useHistory();

    const handleClickTopic = (topicId) => {
        setTimeout(() => {
            setOverflowActive(true)
        }, 1000);
        history.push(`/detail/${topicId}`);
    }

    const handleCloseTopic = () => {
        setShowTopicDetail(false);
        setFromTopic(true);
        setGlobal({
            ...global,
            showingTheme: false
        })
        setOverflowActive(false)
        setTimeout(() => {
            setTopicId('');
            setOverflowActive(true)
        }, 500); 
    }

    const handleChangeOpenTopic= (topicId) => {
        handleCloseTopic();
        setTimeout(() => {
        handleClickTopic(topicId);
        }, 550);
    }

    return (
        <div className='MainView' >
            <div className='down' ref={refDown} ></div>
            <LandingView fromTopic={fromTopic} onClickTopic={handleClickTopic} isScrollBlocked={topicId.length > 0}/>
            {!!topicId && 
                <div className={`topic-container ${showTopicDetail ? 'open' : 'closed'} `}
                style={{overflow: `${overflowActive ? 'unset' : 'hidden'}`}}>
                    <TopicDetailView
                        topicId={topicId}
                        fromMainView={true}
                        handleCloseTopic={handleCloseTopic}
                        onClickTopic={handleChangeOpenTopic}
                         />}
            </div>
            }
        </div>

    )
}

export default MainView;