import React, { useState, useEffect } from 'react';
import ImagesVerticalCarousel from '../ImagesVerticalCarousel/ImagesVerticalCarousel'
import Sticky from 'react-sticky-el';
import ImagesZoom from '../ImagesZoom/ImagesZoom';
import ExtraBlock from '../ExtraBlock/ExtraBlock';

import './SectionView2.scss';

const SectionView2 = (props) => {
    const [formattedText, setFormattedText] = useState([])
    const isRoof = window.location.href.includes('roof');

    useEffect(() => {
        const paragraphArr = props.text && props.text.map(item => {
            const paragraphContent = item.content.map(_item => {
                const contentObj = {
                    text: _item.value,
                    marks: !!_item.marks && !!_item.marks[0] && _item.marks[0].type,
                    type: item.nodeType
                }
                return contentObj
            })
            return paragraphContent;
        })
        setFormattedText(paragraphArr)
    }, [props.text])

    const renderText = () => {
        return (
            formattedText && formattedText.length > 0 && formattedText.map((item, id) => {
                return (
                    <div className='text-paragraph' key={id}>
                        {item.map(textSection => {
                            return <span key={textSection.text}
                                className={`${textSection.marks === 'bold' && 'bold'} 
                                    ${textSection.marks === 'italic' && 'italic'}  
                                    ${textSection.type === 'heading-6' ? 'highlight1' : ''}`}>
                                {textSection.text} </span>
                        })}
                    </div>
                )
            })
        )
    }

    return (
        <Sticky style={{ transform: `translateZ(0px)` }}>
            <div className={`SectionView2 ${props.isOdd ? 'light' : 'dark'} detail `} style={{
                paddingBottom: `${props.imgArray.length > 5 ? isRoof ? '300vh' : '400vh'

                    : '300vh'}`
            }}>
                {props.extraText && <ExtraBlock extraText={props.extraText} extraImg={props.extraImg} isOdd={props.isOdd} />}
                <div className='detail-content2'>
                    {props.imgArray && props.imgArray.length > 0 &&
                        <div className={`images ${props.imgArray.length === 1 && 'zoom'}`}>
                            {props.imgArray.length === 1 ?
                                <ImagesZoom imgArray={props.imgArray} scrollY={props.scrollY} id={props.id} />
                                : <ImagesVerticalCarousel id={props.id} imgArray={props.imgArray} scrollY={props.scrollY} offsetTop={props.offsetTop} />}
                        </div>}
                    <div className={`text ${props.imgArray && props.imgArray.length > 0 ? 'with-img' : 'no-img'}`}>
                        {renderText()}
                    </div>
                </div>
            </div>
        </Sticky>
    )
}

export default SectionView2;
