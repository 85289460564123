import React from 'react';
import { useHistory } from "react-router-dom";

import './TopicBlock.scss';

const TopicBlock = (props) => {
    let history = useHistory();

    const handleClickTopic = (themeId) => { 
        history.push(`/detail/${themeId}`);
    }

    return (
        <div>
            <div className='TopicBlock' >
                <div>
                </div>
                    <div className={`label ${props.position} img-unactive`} onClick={() => handleClickTopic(props.themeId)} >
                        {/* {props.topicId.replace('-', ' ')} ↗ */}
                        {props.topicId.replace('-', ' ')} <span className='bigger'>&#65291;</span>
                </div>

            </div>
        </div>

    )
};

export default TopicBlock