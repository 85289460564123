import React, { useState, useEffect } from 'react';
import DetailSection from '../../../components/DetailSection/DetailSection'
import { useContentful } from 'react-contentful';

import './SectionView.scss';

const SectionView = (props) => {
    const [sectionInfo, setSectionInfo] = useState({})

    const { data } = useContentful({
        contentType: 'sectionBlock',
        query: {
            'fields.sectionId': props.sectionId,
        }
    });
    
    useEffect(() => {
        data && data.items && setSectionInfo(data.items[0].fields)
    }, [data])

    return (
        <div className='SectionView' >
            <DetailSection title={props.sectionId} id={props.id} info={sectionInfo} activeLanguage={props.activeLanguage}/>
        </div>

    );
}

export default SectionView;