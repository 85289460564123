import React, { useRef, useState } from 'react';
import TopicBlock from '../../../components/TopicBlock/TopicBlock';
import { useGlobal } from 'reactn';
import LazyLoad from 'react-lazyload';

import './CollageView.scss';


const duration = 700;
const timingFunction = "ease-in-out"
const windowW = window.innerWidth;
const windowH = window.innerHeight;

const CollageView = ({ src, opacity, topicsArray, viewIndex, onClickTopic }) => {
    let refImg = useRef(null);
    const [activeViewIndex] = useGlobal('activeViewIndex');
    const [anchorWidth, setAnchorWidth] =  useState(activeViewIndex === 1 ? true : false);
    const [language] = useGlobal('language');

    const handleResize = () => {
        activeViewIndex === 1 ?
        setAnchorWidth(false) :
        setAnchorWidth(window.innerWidth/window.innerHeight > 1.4)
    }

    window.addEventListener('resize', handleResize);

    return (
        <div className='CollageView'
            style={{
                opacity: opacity,
                transition: `opacity ${duration / 1000}s ${timingFunction} `,
            }}>
            <LazyLoad>
                <img
                    src={src}
                    alt={'img'}
                    className='img-lines'
                    rel="preload"
                    ref={el => refImg = el}
                    style={{
                        width: `${anchorWidth ? '100vw' : 'auto'}`,
                        height: `${anchorWidth ? 'auto' : '100vh'}`,
                        bottom: `${anchorWidth ? '5rem' : 'unset'}`,
                        top: '0'
                    }}
                    onLoad={() => setAnchorWidth(refImg.naturalWidth / refImg.naturalHeight < windowW / windowH)}
                />
            </LazyLoad>

            {!!topicsArray && topicsArray.length > 0 && topicsArray.map(topic => {
                return (
                    <TopicBlock
                        onClickTopic={onClickTopic}
                        key={topic.positionId}
                        position={topic.positionId}
                        topicId={language === 'DE' ? topic.detailId.de : topic.detailId.en}
                        themeId={topic.themeId}
                        viewIndex={viewIndex} />
                )
            })}
        </div>

    )
}

export default React.memo(CollageView);