import React, { useState, useEffect } from 'react';

import './ImagesVerticalCarousel.scss';
import ImageWithFooter from '../ImageWithFooter/ImageWithFooter';

const ImagesVerticalCarousel = (props) => {
    const windowH = window.innerHeight;
    const [formattedImgArray, setFormattedImgArray] = useState([])
    const [imgScroll, setImgScroll] = useState(0)


    useEffect(() => {
        const procArray = !!props.imgArray && props.imgArray.length > 0 && props.imgArray.map(imgObj => {
            const obj = {
                title: imgObj.fields.title,
                imgSrc: imgObj.fields.file.url
            }
            return obj
        })
        procArray.length > 0 && setFormattedImgArray(procArray)
    }, [props])

    function map_range(value, low1, high1, low2, high2) {
        return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    }
    
    useEffect(() => {
        const transform = map_range(props.scrollY, props.offsetTop, props.offsetTop + windowH * 2, 180, formattedImgArray.length > 4 
            ? 330*formattedImgArray.length 
            : window.location.href.includes('garden') ? 1700 : 
            window.location.href.includes('architect') ? 1400 : 
            window.location.href.includes('roof') ? 900 :
            1000)
        setImgScroll(transform)
    }, [props.scrollY, formattedImgArray.length, props.offsetTop, windowH])

    return (
        <div className='ImagesVerticalCarousel' style={{top: imgScroll}}>
            {formattedImgArray.map(item => {
                return <ImageWithFooter item={item}/>
            })}
        </div>

    )
};

export default ImagesVerticalCarousel