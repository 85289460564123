import React, { useState, useEffect } from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";


import { ReactComponent as Facebook } from '../../assets/facebook.svg'
import { ReactComponent as Youtube } from '../../assets/youtube.svg'
import { ReactComponent as Instagram } from '../../assets/instagram.svg'
import { ReactComponent as Blog } from '../../assets/blog.svg'

import { ReactComponent as BackArrow } from '../../assets/back_arrow_simple.svg'
import { ReactComponent as NextArrow } from '../../assets/next_arrow_simple.svg'
import { ReactComponent as SBMLogo } from '../../assets/logo_SMB_new.svg'
import { ReactComponent as WustenrotLogo } from '../../assets/wustenrot.svg'


import './DetailFooter.scss';

const DetailFooter = (props) => {
    const [linkCopied, setLinkCopied] = useState(false);
    useEffect(() => {
        linkCopied && setTimeout(() => {
            setLinkCopied(false)
        }, 2000)
    }, [linkCopied])
    const handleCopy = () => {
        navigator.clipboard.writeText(window.location.href)
        setLinkCopied(true)
    }

    const renderLinkCopied = () => {
        return (
            <div className='link-copied'>
                Link copied to clipboad ✓
            </div>
        )
    }

    const handleClickImpressum = () => {
        const win = window.open(`${window.location.origin}/impressum`, "_blank");
        win.focus();
    }

    const renderShare = () => {
        return (
            <div className='share-article-content'>
                <div className='share'>Share this article — </div>
                <div className='links'>
                    <div className='share-link'>
                        <TwitterShareButton
                            url={window.location.href}
                            title={'Neue Nationalgalerie elements'}
                        >
                            <p>Twitter</p>
                        </TwitterShareButton>
                    </div>
                    <div className='share-link'>
                        <FacebookShareButton
                            url={window.location.href}
                            quote={'Neue Nationalgalerie elements'}
                        >
                            <p>Facebook</p>
                        </FacebookShareButton>
                    </div>
                    <div className='share-link' onClick={() => handleCopy()}>
                        <p>Copy link</p>
                    </div>
                </div>
            </div>
        )
    }

    const renderFooterMobile = () => {
        return (
            <div className='footer-mobile'>
                <div className='share-article'>
                    <div className='share-article-content'>
                        <div className='share'>Share this article — </div>
                        <div className='links'>
                            <div className='share-link'>
                                <TwitterShareButton
                                    url={window.location.href}
                                    title={'Neue Nationalgalerie elements'}
                                >
                                    <p>Twitter</p>
                                </TwitterShareButton>
                            </div>
                            <div className='share-link'>
                                <FacebookShareButton
                                    url={window.location.href}
                                    quote={'Neue Nationalgalerie elements'}
                                >
                                    <p>Facebook</p>
                                </FacebookShareButton>
                            </div>
                            <div className='share-link' onClick={() => handleCopy()}>
                                <p>Copy link</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='change-topics'>
                    <div className='topic-block' onClick={() => props.handleClickTopic(props.prevTopic)}>
                        <div className='icon'>
                            <BackArrow />
                        </div>
                        <div className='text'> {props.language === 'EN' ? props.prevTopic.themeTitleEn : props.prevTopic.themeTitleGerman}</div>
                    </div>

                    <div className='topic-block' onClick={() => props.handleClickTopic(props.nextTopic)} >

                        <div className='text'> {props.language === 'EN' ? props.nextTopic.themeTitleEn : props.nextTopic.themeTitleGerman}</div>
                        <div className='icon'>
                            <NextArrow />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderFooterDesktop = () => {
        return (
            <div className='footer-desktop'>
                <div className='action-container'>
                    <div className='action1'>
                        <div className='topic-block' onClick={() => props.handleClickTopic(props.prevTopic)}>
                            <div className='icon'>
                                <BackArrow />
                            </div>
                            <div className='text'> {props.language === 'EN' ? props.prevTopic.themeTitleEn : props.prevTopic.themeTitleGerman}</div>
                        </div>
                    </div>
                    <div className='action2'>

                        {renderShare()}

                    </div>
                    <div className='action1'>
                        <div className='topic-block' onClick={() => props.handleClickTopic(props.nextTopic)} >
                            <div className='text'> {props.language === 'EN' ? props.nextTopic.themeTitleEn : props.nextTopic.themeTitleGerman}</div>
                            <div className='icon'>
                                <NextArrow />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='DetailFooter'>
            {/* {linkCopied && renderLinkCopied()} */}
            {renderFooterMobile()}
            {renderFooterDesktop()}

            <div className='footer-container'>
                <div className='media-footer'>
                    <div className='links1'>
                        <a className='link' target="_blank" rel="noopener noreferrer" href='https://www.smb.museum/en/museums-institutions/neue-nationalgalerie/home/'><SBMLogo /></a>

                    </div>
                    <div className='links2'>
                        <div className='links-block'>
                            <a className='media-link' target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/neuenationalgalerie.berlin/ '><Facebook /> Facebook</a>
                            <a className='media-link' target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCQjfdJC-llj4XqT0b4xoMtA '><Youtube /> Youtube</a>
                            <a className='media-link' target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/staatlichemuseenzuberlin/'><Instagram /> Instagram</a>
                            <a className='media-link' target="_blank" rel="noopener noreferrer" href='https://blog.smb.museum/category/serien/baustelle-neue-nationalgalerie/'><Blog/>{'Blog'}</a>
                        </div>
                        <div className='link' onClick={() => handleClickImpressum()}> {props.language === 'EN' ? 'Imprint and Privacy' : 'Impressum und Datenschutz'}</div>
                    </div>

                    <div className='links3'>
                        <a className='link' target="_blank" rel="noopener noreferrer" href='https://wuestenrot-stiftung.de/'><div className='small-svg'><WustenrotLogo /></div></a>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DetailFooter;