import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';

import './ImagesHorizontalCarousel.scss';
import ImageWithFooter from '../ImageWithFooter/ImageWithFooter';

const ImagesHorizontalCarousel = (props) => {
    const [formattedImgArray, setFormattedImgArray] = useState([])
   

    useEffect(() => {
        const procArray = !!props.imgArray && props.imgArray.length > 0 && props.imgArray.map(imgObj => {
            const obj = {
                title: imgObj.fields && imgObj.fields.title,
                imgSrc: imgObj.fields && imgObj.fields.file.url
            }
            return obj
        })
        procArray.length > 0 && setFormattedImgArray(procArray)
    }, [props])

  

    return (

        <Fade bottom>
            <div className={`ImagesHorizontalCarouselScrollMobile ${props.id}`}>
                {formattedImgArray.map(item => {
                    return (
                        <div className='vertical-img-mobile'>
                            <ImageWithFooter item={item} />
                        </div>
                    )
                })}
            </div>
        </Fade>

    )
};

export default ImagesHorizontalCarousel